// Type checking helper functions
const isValidNumber = (value) => {
  return typeof value === 'number' && !isNaN(value) && isFinite(value);
};

const isValidString = (value) => {
  return typeof value === 'string' && value.length > 0;
};

// Safe math helper functions
const safeDivide = (numerator, denominator, defaultValue = 0) => {
  try {
    if (!isValidNumber(numerator) || !isValidNumber(denominator)) return defaultValue;
    if (denominator === 0) return defaultValue;
    return numerator / denominator;
  } catch (error) {
    console.log('Error in division:', error);
    return defaultValue;
  }
};

const safeMultiply = (...factors) => {
  try {
    if (factors.some(f => !isValidNumber(f))) return 0;
    return factors.reduce((acc, val) => acc * val, 1);
  } catch (error) {
    console.log('Error in multiplication:', error);
    return 0;
  }
};

// Safe lookup helper function
const safeGet = (obj, path, defaultValue = null) => {
  try {
    return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? defaultValue;
  } catch (error) {
    console.log(`Error accessing path ${path}:`, error);
    return defaultValue;
  }
};

// Maps lookup IDs to their values and descriptions
export const lookupMap = {
  // Navigation Factors
  'navFactor': {
    value: {
      'Unable to Enter Aisle': 1.5,
      'No Pass': 1.3,
      '2-Wide Passing': 1.05,
      '3-Wide Passing': 1.025
    },
    description: 'Navigation factors based on passing capability',
    type: 'static',
    validate: (value) => isValidNumber(value) ? value : 1.05 // Default to two-wide passing
  },

  // Robot Specifications
  'robotSpecs': {
    value: {
      imperial: {
        'Origin': { avgSpeed: 200 },
        'Locus': { avgSpeed: 180 },
        'HAI': { avgSpeed: 190 }
      },
      metric: {
        'Origin': { avgSpeed: 61 },
        'Locus': { avgSpeed: 55 },
        'HAI': { avgSpeed: 58 }
      }
    },
    description: 'Robot specifications including average speeds in imperial and metric units',
    type: 'static',
    validate: (value) => {
      if (!value?.avgSpeed || !isValidNumber(value.avgSpeed)) {
        console.log('Invalid robot speed specification');
        return { avgSpeed: 0 };
      }
      return value;
    }
  },

  // Cut-through Effectiveness
  'cutThroughEffectiveness': {
    value: [
      { cutThroughs: 0, percentOfAisle: 1.0 },
      { cutThroughs: 1, percentOfAisle: 0.75 },
      { cutThroughs: 2, percentOfAisle: 0.5 },
      { cutThroughs: 3, percentOfAisle: 0.4 },
      { cutThroughs: 4, percentOfAisle: 0.35 },
      { cutThroughs: 5, percentOfAisle: 0.32 }
    ],
    description: 'Effectiveness of cut-throughs in reducing travel distance',
    type: 'static',
    validate: (value) => {
      if (!Array.isArray(value)) return [];
      return value.map(item => ({
        cutThroughs: isValidNumber(item?.cutThroughs) ? item.cutThroughs : 0,
        percentOfAisle: isValidNumber(item?.percentOfAisle) ? item.percentOfAisle : 1.0
      }));
    }
  },

  // Distance References
  'distRef': {
    value: {
      'Corner of Pick Space': 50,
      'Center of Space along End Caps': 65,
      'Center of Space along Aisle Pick Faces': 75,
      'Center of Pick Space': 85
    },
    description: 'Reference distances from various points in the picking space',
    type: 'static',
    validate: (value) => isValidNumber(value) ? value : 0
  },

  // Batch Bot Lookup Tables
  'batchBotImperial': {
    value: {
      'Origin': 'TRUE',
      'Locus': 'FALSE',
      'HAI': 'FALSE'
    },
    description: 'Batch bot compatibility for robots in imperial units',
    type: 'static',
    validate: (value) => isValidString(value) ? value : 'FALSE'
  },

  'batchBotMetric': {
    value: {
      'Origin': 'TRUE',
      'Locus': 'FALSE',
      'HAI': 'FALSE'
    },
    description: 'Batch bot compatibility for robots in metric units',
    type: 'static',
    validate: (value) => isValidString(value) ? value : 'FALSE'
  },

  // FTE Related Lookups
  'fteEfficiencyFactors': {
    value: {
      'standard': 1.0,
      'training': 0.7,
      'peak': 1.2
    },
    description: 'Efficiency factors for different FTE conditions',
    type: 'static',
    validate: (value) => isValidNumber(value) ? value : 1.0
  },

  'jobsPerHourByRobotType': {
    value: {
      'Origin': { picking: 25, putaway: 20 },
      'Locus': { picking: 22, putaway: 18 },
      'HAI': { picking: 23, putaway: 19 }
    },
    description: 'Jobs per hour capabilities by robot type and operation',
    type: 'static',
    validate: (value) => ({
      picking: isValidNumber(value?.picking) ? value.picking : 0,
      putaway: isValidNumber(value?.putaway) ? value.putaway : 0
    })
  },

  // Dynamic Lookups (these might need calculations)
  'avgTasksPerAisle': {
    compute: (userInput, staticInputs) => {
      try {
        const aisleLength = safeGet(userInput, 'totalLengthOfRobotArea', 0);
        const avgTaskSpacing = safeGet(staticInputs, 'averageTaskSpacing', 1);
        return Math.floor(safeDivide(aisleLength, avgTaskSpacing));
      } catch (error) {
        console.log('Error computing avgTasksPerAisle:', error);
        return 0;
      }
    },
    description: 'Average number of tasks that can be performed in one aisle',
    type: 'computed',
    dependencies: ['totalLengthOfRobotArea', 'averageTaskSpacing']
  },

  'pickingDensity': {
    compute: (userInput) => {
      try {
        const area = safeMultiply(
          safeGet(userInput, 'totalLengthOfRobotArea', 0),
          safeGet(userInput, 'totalWidthOfRobotArea', 0)
        );
        const locations = safeGet(userInput, 'designVolumes.totalPickLocations', 0);
        return safeDivide(locations, area);
      } catch (error) {
        console.log('Error computing pickingDensity:', error);
        return 0;
      }
    },
    description: 'Density of picking locations in the robot area',
    type: 'computed',
    dependencies: ['totalLengthOfRobotArea', 'totalWidthOfRobotArea', 'designVolumes.totalPickLocations']
  }
};

// Helper function to get lookup value
export const getLookupValue = (lookupId, params = {}) => {
  try {
    const lookup = lookupMap[lookupId];
    if (!lookup) {
      console.log(`Lookup not found: ${lookupId}`);
      return null;
    }

    if (lookup.type === 'static') {
      const value = lookup.value;
      return lookup.validate ? lookup.validate(value) : value;
    }

    if (lookup.type === 'computed' && lookup.compute) {
      try {
        const result = lookup.compute(params?.userInput, params?.staticInputs);
        return isValidNumber(result) ? result : 0;
      } catch (error) {
        console.log(`Error computing lookup ${lookupId}:`, error);
        return null;
      }
    }

    return null;
  } catch (error) {
    console.log('Error in getLookupValue:', error);
    return null;
  }
};

// Function to get lookup metadata
export const getLookupMetadata = () => {
  try {
    return Object.fromEntries(
      Object.entries(lookupMap).map(([key, lookup]) => [
        key,
        {
          description: lookup?.description || '',
          type: lookup?.type || 'unknown',
          dependencies: (lookup?.type === 'computed' && Array.isArray(lookup?.dependencies)) 
            ? lookup.dependencies 
            : []
        }
      ])
    );
  } catch (error) {
    console.log('Error in getLookupMetadata:', error);
    return {};
  }
}; 