export const SUPER_ADMIN = "Super Admin";
export const LOCUS_ASSET_MANAGER = "Locus Asset Manager";
export const LOCUS_USER_MANAGER = "Locus User Manager";
export const USER_MANAGER = "User Manager";
export const LOCUS_CUSTOMER_USER_MANAGER = "Locus Customer User Manager";
export const LOCUS_TOOLS_USER = "Locus Tools User";
export const LOCUS_SUPPORT_LIAISON = "Locus Support Liaison";
export const SUPPORT_LIAISON = "Support Liaison";
export const LOCUS_CONFIGURATION_ADMIN = "Locus Configuration Admin";
export const CONFIGURATION_ADMIN = "Configuration Admin";
export const CROSS_SITE_VIEWER = "Cross-Site Viewer";
export const VIEWER = "Viewer";
export const LOCUS_TENANT = "Locus Tenant";
export const SYSTEM_INTEGRATOR = "System Integrator";