export const newSite = {
  address: {
    loc: {
      coordinates: [],
      type: "",
    },
    line1: "",
    line2: "",
    city: "",
    countryCode: "1",
    countryShortName: "",
    continentCode: "NA",
    timezone: "",
  },
  code: "",
  active: true,
  status: "Setup In Progress",
  localNetworkRanges: [],
  workingHours: [
    {
      dayOfTheWeek: "t",
      index: 0,
    },
  ],
  biParameters: {},
  penetrationPercentage: 0,
  merakiHostPort: 0,
  merakiTags: [],
  totalRobotSquareFeet: 0,
  totalSquareFeet: 0,
};

export const UpdateSiteRequestObject = {
  friendlyName: "",
  address: {
    loc: {
      coordinates: [],
      type: "",
    },
    line1: "",
    line2: "",
    city: "",
    countryCode: "1",
    countryShortName: "",
    continentCode: "NA",
    timezone: "",
  },
  databaseCluster: "",
  databaseName: "",
  ingestion: {
    eventHubName: "",
    eventHubNamespace: "",
  },
  status: "",
  totalRobotSquareFeet: 0,
  totalSquareFeet: 0,
  code: "", // Netsuite Code
  siteConfigName: "", // Netsuite Site Config Name
  email: "", // Netsuite Contact Email
  integrations: [],
  biParameters: {
    host: "",
    datasetId: "",
  },
  onSiteDatabaseServerName: "", // Onsite VM Server Name
  onSiteDatabaseServerAddress: "", // Onsite VM Server IP
  merakiTags: [],
};
