import { useSelector } from "react-redux";
import {
  CROSS_SITE_VIEWER,
  LOCUS_CUSTOMER_USER_MANAGER,
  LOCUS_TENANT,
  LOCUS_USER_MANAGER,
  SUPER_ADMIN,
  USER_MANAGER,
  VIEWER,
} from "../utils/constants/user";
import { storage } from "../utils/helpers/storage";
import UnauthorizedComponent from "components/Unauthorized/Unauthorized";

const isSuperAdmin = (user) => {
  return (
    user.groups?.filter((group) => {
      return group.name === SUPER_ADMIN;
    }).length > 0
  );
};

/**
 * @param {LocusSite[]} sites
 * @param {LocusGroup[]} groups
 */
export const getSitesAvailableToUser = ({ sites, groups }) => {
  if (groups?.length) {
    if (hasAccessToAllSites(groups)) {
      return sites;
    }

    /* Match sites with viewer permissions and filter decommissioned sites */
    return sites
      ?.filter((site) => groups.find((group) => group.site === site.id))
      .filter((site) => site.status !== "Decommissioned");
  }
  return [];
};
/**
 * @param {LocusGroup[]} groups
 *
 * https://locusrobotics.atlassian.net/browse/HUB-2336
 */
export const hasAccessToAllSites = (groups) => {
  return (
    groups?.filter(
      (group) =>
        group.name === SUPER_ADMIN ||
        group.name === CROSS_SITE_VIEWER ||
        storage.getLocus(),
    ).length > 0
  );
};

/**
 * @param {LocusUser} activeUser
 * @param {LocusGroup[]} allowedGroups
 */
export const canViewElement = ({ allowedGroups, activeUser }) => {
  if (!activeUser) {
    return false;
  }

  if (isSuperAdmin(activeUser)) return true;

  // Match allowed groups with active user's groups
  if (allowedGroups?.length) {
    if (allowedGroups.includes(LOCUS_TENANT) && activeUser?.domain === "locus")
      return true;
    if (activeUser?.groups?.length === 0) return false;

    // console.log(activeUser?.groups?.find((group) => group.name.includes(VIEWER)));
    if (activeUser?.groups?.some((group) => allowedGroups.includes(group.name)))
      return true;

    if (
      allowedGroups.includes(VIEWER) &&
      activeUser?.groups?.find((group) => group.name.includes(VIEWER))
    )
      return true;
  }

  // If user does not match on `allowedGroups` or is not a Super Admin
  return false;
};

export const hasRequiredClient = ({ allowedClients, selectedClient }) => {
  if (!selectedClient || !allowedClients?.length) {
    return true;
  }

  // Match allowed groups with active user's groups
  if (allowedClients?.length) {
    return allowedClients.includes(selectedClient.domain);
  }

  // If user does not match on `allowedClients` or is not a Super Admin
  return false;
};

/**
 * @param {LocusUser} activeUser
 * @param {ViewState} viewState
 * @param {LocusMenuItem} navItem
 * @return boolean
 */
export const showMenuItem = ({
  activeUser,
  viewState,
  navItem,
  selectedClient,
}) => {
  if (navItem.view) {
    if (navItem.allowAll) return true;
    if (
      canViewElement({ allowedGroups: navItem.allowedGroups, activeUser }) &&
      hasRequiredClient({
        allowedClients: navItem.allowedClients,
        selectedClient,
      })
    ) {
      return navItem.view.includes(viewState);
    }
  }
  return false;
};

export const ProtectedElement = ({
  element: Element,
  allowedGroups,
  ...rest
}) => {
  const { activeUser } = useSelector((state) => state.user);
  const { selectedClient } = useSelector(
    (state) => state.customer,
  );
  if (canViewElement({ allowedGroups, activeUser })) {
    return <Element {...rest} />;
  } else {
    return <UnauthorizedComponent />;
  }
};
