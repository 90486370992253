import { getLookupValue } from './lookupReferences';

// Type checking helper functions
const isValidNumber = (value) => {
  return typeof value === 'number' && !isNaN(value) && isFinite(value);
};

const isValidString = (value) => {
  return typeof value === 'string' && value.length > 0;
};

const isValidArray = (value) => {
  return Array.isArray(value) && value.length > 0;
};

const isValidObject = (value) => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

// Safe math helper functions with enhanced type checking
const safeDivide = (numerator, denominator, defaultValue = 0) => {
  try {
    if (!isValidNumber(numerator) || !isValidNumber(denominator)) return defaultValue;
    if (denominator === 0) return defaultValue;
    return numerator / denominator;
  } catch (error) {
    console.log('Error in division:', error);
    return defaultValue;
  }
};

const safeMultiply = (...factors) => {
  try {
    if (factors.some(f => !isValidNumber(f))) return 0;
    return factors.reduce((acc, val) => acc * val, 1);
  } catch (error) {
    console.log('Error in multiplication:', error);
    return 0;
  }
};

// Safe lookup helper with enhanced error handling
const safeLookup = (lookupFn, key, params = {}) => {
  try {
    if (!lookupFn || typeof lookupFn !== 'function') {
      console.log('Invalid lookup function');
      return null;
    }
    if (!isValidString(key)) {
      console.log('Invalid lookup key');
      return null;
    }
    return lookupFn(key, params);
  } catch (error) {
    console.log(`Error looking up ${key}:`, error);
    return null;
  }
};

// Safe object access helper
const safeGet = (obj, path, defaultValue = null) => {
  try {
    if (!isValidObject(obj)) return defaultValue;
    return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? defaultValue;
  } catch (error) {
    console.log(`Error accessing path ${path}:`, error);
    return defaultValue;
  }
};

// Helper function to set nested value with validation
const setNestedValue = (obj, path, value) => {
  try {
    if (!isValidObject(obj) || !isValidString(path)) {
      console.log('Invalid object or path in setNestedValue');
      return;
    }
    const [category, type] = path.split('.');
    if (!obj[category]) {
      obj[category] = {};
    }
    obj[category][type] = value;
  } catch (error) {
    console.log('Error in setNestedValue:', error);
  }
};

// Helper function to get nested value with validation
const getNestedValue = (obj, path) => {
  try {
    if (!isValidObject(obj) || !isValidString(path)) {
      console.log('Invalid object or path in getNestedValue');
      return null;
    }
    const [category, type] = path.split('.');
    return obj[category]?.[type];
  } catch (error) {
    console.log('Error in getNestedValue:', error);
    return null;
  }
};

// Helper function for unit conversion with validation
const convertImperialToMetric = (value, fromUnit, toUnit) => {
  try {
    if (!isValidNumber(value)) return 0;
    if (!isValidString(fromUnit) || !isValidString(toUnit)) {
      console.log('Invalid units in conversion');
      return value;
    }
    
    switch(`${fromUnit}_${toUnit}`) {
      case 'ft_m':
        return value * 0.3048;
      default:
        return value;
    }
  } catch (error) {
    console.log('Error in unit conversion:', error);
    return 0;
  }
};

// Maps calculation IDs to their computation functions and descriptions
export const calculationMap = {
  // Robot Type Calculations
  'robot.picking': {
    compute: (userInput) => {
      try {
        // Get first word before space from robotOrigin
        return (userInput?.robotOrigin || '').split(' ')[0] || '';
      } catch (error) {
        console.log('Error in robot.picking calculation:', error);
        return '';
      }
    },
    description: 'Extracts the robot type from robotOrigin string',
    calculation: 'First word of robotOrigin (before space)',
    dependencies: ['robotOrigin']
  },

  'robot.putaway': {
    compute: (userInput) => {
      try {
        // Get first word before space from robotOrigin
        return (userInput?.robotOrigin || '').split(' ')[0] || '';
      } catch (error) {
        console.log('Error in robot.putaway calculation:', error);
        return '';
      }
    },
    description: 'Extracts the robot type from robotOrigin string',
    calculation: 'First word of robotOrigin (before space)',
    dependencies: ['robotOrigin']
  },

  // Batch Bot Settings
  'batchBot.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // Use batchBot setting directly from static inputs
        return staticInputs?.batchBot || 'FALSE';
      } catch (error) {
        console.log('Error in batchBot.picking calculation:', error);
        return 'FALSE';
      }
    },
    description: 'Uses the batch bot setting from static inputs',
    calculation: 'Direct value from batchBot static input',
    dependencies: ['staticInputs.batchBot']
  },

  'batchBot.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // Use batchBot setting directly from static inputs
        return staticInputs?.batchBot || 'FALSE';
      } catch (error) {
        console.log('Error in batchBot.putaway calculation:', error);
        return 'FALSE';
      }
    },
    description: 'Uses the batch bot setting from static inputs',
    calculation: 'Direct value from batchBot static input',
    dependencies: ['staticInputs.batchBot']
  },

  // Robot Speed
  'robotSpeed.picking': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const robotType = (userInput?.robotOrigin || '').split(' ')[0].toLowerCase().replace('.', '_');
        const robotSpecs = lookupReference?.robotSpecs || {};
        
        // Lookup robot speed based on measurement system
        if (isImperial) {
          return robotSpecs.imperial?.[robotType]?.avgSpeed || 0;
        } else {
          return robotSpecs.metric?.[robotType]?.avgSpeed || 0;
        }
      } catch (error) {
        return 0;
      }
    },
    description: 'Determines robot speed based on robot type and measurement system',
    calculation: 'If Imperial: lookup robot speed from imperial specs table, else lookup from metric specs table',
    dependencies: ['metricOrImperial', 'robotOrigin', 'lookupReference.robotSpecs']
  },

  'robotSpeed.putaway': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const robotType = (userInput?.robotOrigin || '').split(' ')[0].toLowerCase().replace('.', '_');
        const robotSpecs = lookupReference?.robotSpecs || {};
        
        // Lookup robot speed based on measurement system
        if (isImperial) {
          return robotSpecs.imperial?.[robotType]?.avgSpeed || 0;
        } else {
          return robotSpecs.metric?.[robotType]?.avgSpeed || 0;
        }
      } catch (error) {
        console.log('Error in robotSpeed.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Determines robot speed based on robot type and measurement system',
    calculation: 'If Imperial: lookup robot speed from imperial specs table, else lookup from metric specs table',
    dependencies: ['metricOrImperial', 'robotOrigin', 'lookupReference.robotSpecs']
  },

  // Lines per Mission
  'linesPerMission.picking': {
    compute: (userInput) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        const ordersPerDay = userInput?.designVolumes?.ordersPerDay?.picking || 0;
        const containersPerMission = userInput?.designVolumes?.containersPerMission?.picking || 0;
        
        // Calculate lines per mission based on daily volumes
        if (ordersPerDay === 0) return 0;
        return (linesPerDay / ordersPerDay) * containersPerMission;
      } catch (error) {
        console.log('Error in linesPerMission.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates lines per mission based on daily volumes and containers per mission',
    calculation: '(linesPerDay / ordersPerDay) × containersPerMission',
    dependencies: ['designVolumes.linesPerDay.picking', 'designVolumes.ordersPerDay.picking', 'designVolumes.containersPerMission.picking']
  },

  'linesPerMission.putaway': {
    compute: (userInput) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const ordersPerDay = userInput?.designVolumes?.ordersPerDay?.putaway || 0;
        const containersPerMission = userInput?.designVolumes?.containersPerMission?.putaway || 0;
        
        // Calculate lines per mission based on daily volumes
        if (ordersPerDay === 0) return 0;
        return (linesPerDay / ordersPerDay) * containersPerMission;
      } catch (error) {
        console.log('Error in linesPerMission.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates lines per mission based on daily volumes and containers per mission',
    calculation: '(linesPerDay / ordersPerDay) × containersPerMission',
    dependencies: ['designVolumes.linesPerDay.putaway', 'designVolumes.ordersPerDay.putaway', 'designVolumes.containersPerMission.putaway']
  },

  // Lines per Stop
  'linesPerStop.picking': {
    compute: (userInput) => {
      try {
        // Direct value from design volumes
        return userInput?.designVolumes?.linesPerStop?.picking || 0;
      } catch (error) {
        console.log('Error in linesPerStop.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets lines per stop value from design volumes',
    calculation: 'Direct value from designVolumes.linesPerStop.picking',
    dependencies: ['designVolumes.linesPerStop.picking']
  },

  'linesPerStop.putaway': {
    compute: (userInput) => {
      try {
        // Direct value from design volumes
        return userInput?.designVolumes?.linesPerStop?.putaway || 0;
      } catch (error) {
        console.log('Error in linesPerStop.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets lines per stop value from design volumes',
    calculation: 'Direct value from designVolumes.linesPerStop.putaway',
    dependencies: ['designVolumes.linesPerStop.putaway']
  },

  // Navigation Factor Calculations
  'navigationFactor.picking': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        // C118 = XLOOKUP(C24,B61:B64,C61:C64,"",0)
        const passingCapability = userInput?.determinedPassingCapability || '';
        const navFactor = lookupReference?.navFactor || {};
        
        // Direct lookup without case transformation to ensure exact match
        return navFactor[passingCapability] || 1.05; // Default to two-wide passing if no match
      } catch (error) {
        console.log('Error in navigationFactor.picking calculation:', error);
        return 0;
      }
    },
    description: 'Looks up navigation factor based on passing capability',
    calculation: 'XLOOKUP based on determinedPassingCapability',
    dependencies: ['determinedPassingCapability', 'lookupReference.navFactor']
  },

  'navigationFactor.putaway': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        // D118 = XLOOKUP(C24,B61:B64,C61:C64,"",0)
        const passingCapability = userInput?.determinedPassingCapability || '';
        const navFactor = lookupReference?.navFactor || {};
        
        // Direct lookup without case transformation to ensure exact match
        return navFactor[passingCapability] || 1.05; // Default to two-wide passing if no match
      } catch (error) {
        console.log('Error in navigationFactor.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Looks up navigation factor based on passing capability',
    calculation: 'XLOOKUP based on determinedPassingCapability',
    dependencies: ['determinedPassingCapability', 'lookupReference.navFactor']
  },

  // Travel Distance Calculations
  'travelToNextPickDistance.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const { totalLengthOfRobotArea = 0, totalWidthOfRobotArea = 0, numberOfCutThroughsInRobotArea = 0 } = userInput || {};
        const navFactor = results['navigationFactor.picking'] || 1.05;
        
        const avgTasksPerAisle = safeLookup(getLookupValue, 'avgTasksPerAisle', { userInput, staticInputs }) || 1;
        
        const cutThroughEffects = safeLookup(getLookupValue, 'cutThroughEffectiveness') || [];
        const cutThroughEffect = cutThroughEffects.find(
          cut => cut?.cutThroughs === numberOfCutThroughsInRobotArea
        )?.percentOfAisle || 0.5;

        const minDistance = Math.min(
          safeMultiply(cutThroughEffect, totalLengthOfRobotArea),
          safeDivide(totalLengthOfRobotArea, avgTasksPerAisle)
        );

        const widthComponent = safeMultiply(
          totalWidthOfRobotArea,
          staticInputs?.pickingAvgJobPercentOfZone || 0,
          safeDivide(1, results['linesPerMission.picking'] || 1)
        );

        return safeMultiply(minDistance + widthComponent, navFactor);
      } catch (error) {
        console.log('Error in travelToNextPickDistance.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates the travel distance to the next pick location',
    calculation: `travelDistance = (
  MIN(cutThroughEffect × areaLength, areaLength / tasksPerAisle) +
  (areaWidth × zonePercentage × (1 / linesPerMission))
) × navigationFactor`,
    dependencies: [
      'totalLengthOfRobotArea',
      'totalWidthOfRobotArea', 
      'numberOfCutThroughsInRobotArea',
      'navigationFactor.picking',
      'linesPerMission.picking'
    ]
  },

  // Task Time Calculations
  'inductTotalTime.picking': {
    compute: (userInput) => {
      try {
        // Instead of '=C30'
        return userInput?.designVolumes?.inductLoadTime?.picking || 0;
      } catch (error) {
        console.log('Error in inductTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets induct total time from design volumes',
    calculation: 'Uses userInput.designVolumes.inductLoadTime.picking value',
    dependencies: ['designVolumes.inductLoadTime.picking']
  },

  'inductTotalTime.putaway': {
    compute: (userInput) => {
      try {
        // Instead of '=D30'
        return userInput?.designVolumes?.inductLoadTime?.putaway || 0;
      } catch (error) {
        console.log('Error in inductTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets induct total time from design volumes',
    calculation: 'Uses userInput.designVolumes.inductLoadTime.putaway value',
    dependencies: ['designVolumes.inductLoadTime.putaway']
  },

  'autonomousLoadTime.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I16'
        return staticInputs?.autonomousLoadTime || 0;
      } catch (error) {
        console.log('Error in autonomousLoadTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets autonomous load time from static inputs',
    calculation: 'Uses staticInputs.autonomousLoadTime value',
    dependencies: ['staticInputs.autonomousLoadTime']
  },

  'autonomousLoadTime.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I16'
        return staticInputs?.autonomousLoadTime || 0;
      } catch (error) {
        console.log('Error in autonomousLoadTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets autonomous load time from static inputs',
    calculation: 'Uses staticInputs.autonomousLoadTime value',
    dependencies: ['staticInputs.autonomousLoadTime']
  },

  'waitForFteTime.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I12'
        return staticInputs?.waitTaskTime || 0;
      } catch (error) {
        console.log('Error in waitForFteTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets wait task time from static inputs',
    calculation: 'Uses staticInputs.waitTaskTime value',
    dependencies: ['staticInputs.waitTaskTime']
  },

  'waitForFteTime.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I12'
        return staticInputs?.waitTaskTime || 0;
      } catch (error) {
        console.log('Error in waitForFteTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets wait task time from static inputs',
    calculation: 'Uses staticInputs.waitTaskTime value',
    dependencies: ['staticInputs.waitTaskTime']
  },

  'waitForFteQty.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=C102'
        return results?.linesPerMission?.picking || 0;
      } catch (error) {
        console.log('Error in waitForFteQty.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets lines per mission as wait FTE quantity',
    calculation: 'Uses results.linesPerMission.picking value',
    dependencies: ['linesPerMission.picking']
  },

  'waitForFteQty.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=D102'
        return results?.linesPerMission?.putaway || 0;
      } catch (error) {
        console.log('Error in waitForFteQty.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets lines per mission as wait FTE quantity',
    calculation: 'Uses results.linesPerMission.putaway value',
    dependencies: ['linesPerMission.putaway']
  },

  'waitForFteTotalTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=IFERROR(C110*C111,"")'
        const waitTime = results?.waitForFteTime?.picking || 0;
        const waitQty = results?.waitForFteQty?.picking || 0;
        return waitTime * waitQty;
      } catch (error) {
        console.log('Error in waitForFteTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total wait time for FTE',
    calculation: 'Multiplies waitForFteTime by waitForFteQty',
    dependencies: ['waitForFteTime.picking', 'waitForFteQty.picking']
  },

  'waitForFteTotalTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=IFERROR(D110*D111,"")'
        const waitTime = results?.waitForFteTime?.putaway || 0;
        const waitQty = results?.waitForFteQty?.putaway || 0;
        return waitTime * waitQty;
      } catch (error) {
        console.log('Error in waitForFteTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total wait time for FTE',
    calculation: 'Multiplies waitForFteTime by waitForFteQty',
    dependencies: ['waitForFteTime.putaway', 'waitForFteQty.putaway']
  },

  'pickLineTime.picking': {
    compute: (userInput) => {
      try {
        // Get task time per line from design volumes
        return userInput?.designVolumes?.taskTimePerLine?.picking || 0;
      } catch (error) {
        console.log('Error in pickLineTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets task time per line from design volumes',
    calculation: 'Direct value from designVolumes.taskTimePerLine.picking',
    dependencies: ['designVolumes.taskTimePerLine.picking']
  },

  'pickLineTime.putaway': {
    compute: (userInput) => {
      try {
        // Get task time per line from design volumes
        return userInput?.designVolumes?.taskTimePerLine?.putaway || 0;
      } catch (error) {
        console.log('Error in pickLineTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets task time per line from design volumes',
    calculation: 'Direct value from designVolumes.taskTimePerLine.putaway',
    dependencies: ['designVolumes.taskTimePerLine.putaway']
  },

  'pickLineQty.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Use lines per mission value
        return results?.linesPerMission?.picking || 0;
      } catch (error) {
        console.log('Error in pickLineQty.picking calculation:', error);
        return 0;
      }
    },
    description: 'Uses lines per mission as pick line quantity',
    calculation: 'Direct value from linesPerMission.picking',
    dependencies: ['linesPerMission.picking']
  },

  'pickLineQty.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Use lines per mission value
        return results?.linesPerMission?.putaway || 0;
      } catch (error) {
        console.log('Error in pickLineQty.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Uses lines per mission as pick line quantity',
    calculation: 'Direct value from linesPerMission.putaway',
    dependencies: ['linesPerMission.putaway']
  },

  'pickLineTotalTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Multiply pick line time by quantity
        const pickTime = results?.pickLineTime?.picking || 0;
        const pickQty = results?.pickLineQty?.picking || 0;
        return pickTime * pickQty;
      } catch (error) {
        console.log('Error in pickLineTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total pick line time by multiplying time per line by quantity',
    calculation: 'pickLineTime × pickLineQty',
    dependencies: ['pickLineTime.picking', 'pickLineQty.picking']
  },

  'pickLineTotalTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Multiply pick line time by quantity
        const pickTime = results?.pickLineTime?.putaway || 0;
        const pickQty = results?.pickLineQty?.putaway || 0;
        return pickTime * pickQty;
      } catch (error) {
        console.log('Error in pickLineTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total pick line time by multiplying time per line by quantity',
    calculation: 'pickLineTime × pickLineQty',
    dependencies: ['pickLineTime.putaway', 'pickLineQty.putaway']
  },

  // Average Tasks per Aisle
  'avgTasksPerAisle.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate average tasks per aisle
        const linesPerMission = results?.linesPerMission?.picking || 0;
        const linesPerStop = results?.linesPerStop?.picking || 0;
        const avgJobPercentageOfZone = staticInputs?.pickingAvgJobPercentOfZone || 0;
        const numberOfAisles = userInput?.numberOfAislesInRobotArea || 0;

        if (linesPerStop === 0 || avgJobPercentageOfZone === 0) return 0;
        return (linesPerMission / linesPerStop / avgJobPercentageOfZone) * numberOfAisles;
      } catch (error) {
        console.log('Error in avgTasksPerAisle.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates average tasks per aisle based on lines and zone percentage',
    calculation: '(linesPerMission / linesPerStop / avgJobPercentageOfZone) × numberOfAisles',
    dependencies: ['linesPerMission.picking', 'linesPerStop.picking', 'staticInputs.pickingAvgJobPercentOfZone', 'numberOfAislesInRobotArea']
  },

  'avgTasksPerAisle.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate average tasks per aisle
        const linesPerMission = results?.linesPerMission?.putaway || 0;
        const linesPerStop = results?.linesPerStop?.putaway || 0;
        const avgJobPercentageOfZone = staticInputs?.putawayAvgJobPercentOfZone || 0;
        const numberOfAisles = userInput?.numberOfAislesInRobotArea || 0;

        if (linesPerStop === 0 || avgJobPercentageOfZone === 0) return 0;
        return (linesPerMission / linesPerStop / avgJobPercentageOfZone) * numberOfAisles;
      } catch (error) {
        console.log('Error in avgTasksPerAisle.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates average tasks per aisle based on lines and zone percentage',
    calculation: '(linesPerMission / linesPerStop / avgJobPercentageOfZone) × numberOfAisles',
    dependencies: ['linesPerMission.putaway', 'linesPerStop.putaway', 'staticInputs.putawayAvgJobPercentOfZone', 'numberOfAislesInRobotArea']
  },

  // Average Job Percentage of Zone
  'avgJobPercentageOfZone.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // Get picking average job percentage of zone from static inputs
        return staticInputs?.pickingAvgJobPercentOfZone || 0;
      } catch (error) {
        console.log('Error in avgJobPercentageOfZone.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets picking average job percentage of zone from static inputs',
    calculation: 'Direct value from staticInputs.pickingAvgJobPercentOfZone',
    dependencies: ['staticInputs.pickingAvgJobPercentOfZone']
  },

  'avgJobPercentageOfZone.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // Get putaway average job percentage of zone from static inputs
        return staticInputs?.putawayAvgJobPercentOfZone || 0;
      } catch (error) {
        console.log('Error in avgJobPercentageOfZone.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets putaway average job percentage of zone from static inputs',
    calculation: 'Direct value from staticInputs.putawayAvgJobPercentOfZone',
    dependencies: ['staticInputs.putawayAvgJobPercentOfZone']
  },

  // Navigation Factor
  'navigationFactor.picking': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        // Instead of '=XLOOKUP(C24,B61:B64,C61:C64,"",0)'
        const passingCapability = userInput?.determinedPassingCapability || '';
        const navFactor = lookupReference?.navFactor || {};
        return navFactor[passingCapability] || 1.05; // Default to two-wide passing
      } catch (error) {
        console.log('Error in navigationFactor.picking calculation:', error);
        return 0;
      }
    },
    description: 'Looks up navigation factor based on passing capability',
    calculation: 'Looks up value from navFactor table using determinedPassingCapability',
    dependencies: ['determinedPassingCapability', 'lookupReference.navFactor']
  },

  'navigationFactor.putaway': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        // Instead of '=XLOOKUP(C24,B61:B64,C61:C64,"",0)'
        const passingCapability = userInput?.determinedPassingCapability || '';
        const navFactor = lookupReference?.navFactor || {};
        return navFactor[passingCapability] || 1.05; // Default to two-wide passing
      } catch (error) {
        console.log('Error in navigationFactor.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Looks up navigation factor based on passing capability',
    calculation: 'Looks up value from navFactor table using determinedPassingCapability',
    dependencies: ['determinedPassingCapability', 'lookupReference.navFactor']
  },

  // Travel to Next Pick Distance
  'travelToNextPickDistance.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate travel distance to next pick
        const cutThroughs = userInput?.numberOfCutThroughsInRobotArea || 0;
        const aisleLength = userInput?.totalLengthOfRobotArea || 0;
        const avgTasksPerAisle = results?.avgTasksPerAisle?.picking || 1;
        const aisleWidth = userInput?.aisleWidth || 0;
        const avgJobPercentageOfZone = results?.avgJobPercentageOfZone?.picking || 0;
        const pickLineQty = results?.pickLineQty?.picking || 1;
        const navigationFactor = results?.navigationFactor?.picking || 0;
        
        // Get cut through effectiveness factor
        const cutThroughFactor = lookupReference?.cutThroughEffectiveness?.[cutThroughs] || 0;
        
        // Calculate minimum distance
        const minDistance = Math.min(
          cutThroughFactor * aisleLength,
          aisleLength / avgTasksPerAisle
        );
        
        // Calculate total distance with navigation factor
        return minDistance * aisleLength + (aisleWidth * avgJobPercentageOfZone * (1/pickLineQty)) * navigationFactor;
      } catch (error) {
        console.log('Error in travelToNextPickDistance.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to next pick location considering cut throughs and navigation',
    calculation: 'MIN(cutThroughFactor × aisleLength, aisleLength/avgTasksPerAisle) × aisleLength + (aisleWidth × avgJobPercentageOfZone × (1/pickLineQty)) × navigationFactor',
    dependencies: [
      'numberOfCutThroughsInRobotArea',
      'totalLengthOfRobotArea',
      'avgTasksPerAisle.picking',
      'aisleWidth',
      'avgJobPercentageOfZone.picking',
      'pickLineQty.picking',
      'navigationFactor.picking',
      'lookupReference.cutThroughEffectiveness'
    ]
  },

  'travelToNextPickDistance.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate travel distance to next pick
        const cutThroughs = userInput?.numberOfCutThroughsInRobotArea || 0;
        const aisleLength = userInput?.totalLengthOfRobotArea || 0;
        const avgTasksPerAisle = results?.avgTasksPerAisle?.putaway || 1;
        const aisleWidth = userInput?.aisleWidth || 0;
        const avgJobPercentageOfZone = results?.avgJobPercentageOfZone?.putaway || 0;
        const pickLineQty = results?.pickLineQty?.putaway || 1;
        const navigationFactor = results?.navigationFactor?.putaway || 0;
        
        // Get cut through effectiveness factor
        const cutThroughFactor = lookupReference?.cutThroughEffectiveness?.[cutThroughs] || 0;
        
        // Calculate minimum distance
        const minDistance = Math.min(
          cutThroughFactor * aisleLength,
          aisleLength / avgTasksPerAisle
        );
        
        // Calculate total distance with navigation factor
        return minDistance * aisleLength + (aisleWidth * avgJobPercentageOfZone * (1/pickLineQty)) * navigationFactor;
      } catch (error) {
        console.log('Error in travelToNextPickDistance.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to next pick location considering cut throughs and navigation',
    calculation: 'MIN(cutThroughFactor × aisleLength, aisleLength/avgTasksPerAisle) × aisleLength + (aisleWidth × avgJobPercentageOfZone × (1/pickLineQty)) �� navigationFactor',
    dependencies: [
      'numberOfCutThroughsInRobotArea',
      'totalLengthOfRobotArea',
      'avgTasksPerAisle.putaway',
      'aisleWidth',
      'avgJobPercentageOfZone.putaway',
      'pickLineQty.putaway',
      'navigationFactor.putaway',
      'lookupReference.cutThroughEffectiveness'
    ]
  },

  // Travel to Next Pick Time
  'travelToNextPickTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate time based on distance and robot speed
        const distance = results?.travelToNextPickDistance?.picking || 0;
        const robotSpeed = results?.robotSpeed?.picking || 1; // Avoid division by zero
        return distance / robotSpeed;
      } catch (error) {
        console.log('Error in travelToNextPickTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel time to next pick based on distance and robot speed',
    calculation: 'travelToNextPickDistance / robotSpeed',
    dependencies: ['travelToNextPickDistance.picking', 'robotSpeed.picking']
  },

  'travelToNextPickTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate time based on distance and robot speed
        const distance = results?.travelToNextPickDistance?.putaway || 0;
        const robotSpeed = results?.robotSpeed?.putaway || 1; // Avoid division by zero
        return distance / robotSpeed;
      } catch (error) {
        console.log('Error in travelToNextPickTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel time to next pick based on distance and robot speed',
    calculation: 'travelToNextPickDistance / robotSpeed',
    dependencies: ['travelToNextPickDistance.putaway', 'robotSpeed.putaway']
  },

  // Travel to Next Pick Qty
  'travelToNextPickQty.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate quantity based on lines per mission and stop
        const linesPerMission = results?.linesPerMission?.picking || 0;
        const linesPerStop = results?.linesPerStop?.picking || 0;
        
        if (linesPerStop === 0) return 0;
        return (linesPerMission / linesPerStop) - 1;
      } catch (error) {
        console.log('Error in travelToNextPickQty.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of next picks based on lines per mission and stop',
    calculation: '(linesPerMission / linesPerStop) - 1',
    dependencies: ['linesPerMission.picking', 'linesPerStop.picking']
  },

  'travelToNextPickQty.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate quantity based on lines per mission and stop
        const linesPerMission = results?.linesPerMission?.putaway || 0;
        const linesPerStop = results?.linesPerStop?.putaway || 0;
        
        if (linesPerStop === 0) return 0;
        return (linesPerMission / linesPerStop) - 1;
      } catch (error) {
        console.log('Error in travelToNextPickQty.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of next picks based on lines per mission and stop',
    calculation: '(linesPerMission / linesPerStop) - 1',
    dependencies: ['linesPerMission.putaway', 'linesPerStop.putaway']
  },

  // Travel to Next Pick Total Time
  'travelToNextPickTotalTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate total time based on quantity and time per pick
        const qty = results?.travelToNextPickQty?.picking || 0;
        const time = results?.travelToNextPickTime?.picking || 0;
        return qty * time;
      } catch (error) {
        console.log('Error in travelToNextPickTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total travel time to next picks based on quantity and time per pick',
    calculation: 'travelToNextPickQty × travelToNextPickTime',
    dependencies: ['travelToNextPickQty.picking', 'travelToNextPickTime.picking']
  },

  'travelToNextPickTotalTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate total time based on quantity and time per pick
        const qty = results?.travelToNextPickQty?.putaway || 0;
        const time = results?.travelToNextPickTime?.putaway || 0;
        return qty * time;
      } catch (error) {
        console.log('Error in travelToNextPickTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total travel time to next picks based on quantity and time per pick',
    calculation: 'travelToNextPickQty × travelToNextPickTime',
    dependencies: ['travelToNextPickQty.putaway', 'travelToNextPickTime.putaway']
  },

  // Pick Area to Dropoff Adjustment
  'pickAreaToDropoffAdjustment.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // Convert pick area to dropoff adjustment based on measurement system
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const adjustment = staticInputs?.pickAreaToDropoffAdjustment || 0;
        
        if (isImperial) {
          return adjustment;
        } else {
          return convertImperialToMetric(adjustment, 'ft', 'm');
        }
      } catch (error) {
        console.log('Error in pickAreaToDropoffAdjustment.picking calculation:', error);
        return 0;
      }
    },
    description: 'Converts pick area to dropoff adjustment based on measurement system',
    calculation: 'If Imperial: adjustment value, else: convert adjustment from ft to m',
    dependencies: ['metricOrImperial', 'staticInputs.pickAreaToDropoffAdjustment']
  },

  'pickAreaToDropoffAdjustment.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // Convert pick area to dropoff adjustment based on measurement system
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const adjustment = staticInputs?.pickAreaToDropoffAdjustment || 0;
        
        if (isImperial) {
          return adjustment;
        } else {
          return convertImperialToMetric(adjustment, 'ft', 'm');
        }
      } catch (error) {
        console.log('Error in pickAreaToDropoffAdjustment.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Converts pick area to dropoff adjustment based on measurement system',
    calculation: 'If Imperial: adjustment value, else: convert adjustment from ft to m',
    dependencies: ['metricOrImperial', 'staticInputs.pickAreaToDropoffAdjustment']
  },

  // Efficiency and Throughput Calculations
  'activePickTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        console.log(999999)
        const waitFteTotal = results['waitForFteTotalTime.picking'] || 0;
        const waitFteBase = results['waitForFteTime.picking'] || 0;
        const pickLineTotal = results['pickLineTotalTime.picking'] || 0;
        const travelNextPickTotal = results['travelToNextPickTotalTime.picking'] || 0;
        
        return (waitFteTotal - waitFteBase) + pickLineTotal + travelNextPickTotal;
      } catch (error) {
        console.log('Error in activePickTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total active picking time',
    calculation: 'activeTime = waitForFteTotalTime - waitForFteTime + pickLineTotalTime + travelToNextPickTotalTime',
    dependencies: ['waitForFteTotalTime.picking', 'waitForFteTime.picking', 'pickLineTotalTime.picking', 'travelToNextPickTotalTime.picking']
  },

  'averageMissionTimeSec.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Get all the component times
        const inductTime = results['inductTotalTime.picking'] || 0;
        const waitTime = results['waitForFteTotalTime.picking'] || 0;
        const pickTime = results['pickLineTotalTime.picking'] || 0;
        const travelNextTime = results['travelToNextPickTotalTime.picking'] || 0;
        const dropoffTime = results['dropoffTotalTime.picking'] || 0;
        const queueTime = results['dropoffQueueTotalTime.picking'] || 0;
        
        // Sum up all times
        const totalTime = inductTime + waitTime + pickTime + travelNextTime + dropoffTime + queueTime;
        
        console.log('Mission time components:', {
          inductTime,
          waitTime,
          pickTime,
          travelNextTime,
          dropoffTime,
          queueTime,
          totalTime
        });
        
        return totalTime || 0;
      } catch (error) {
        console.log('Error in averageMissionTimeSec.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total mission time in seconds',
    calculation: 'Sum of all component times: induct + wait + pick + travel + dropoff + queue',
    dependencies: [
      'inductTotalTime.picking',
      'waitForFteTotalTime.picking', 
      'pickLineTotalTime.picking',
      'travelToNextPickTotalTime.picking',
      'dropoffTotalTime.picking',
      'dropoffQueueTotalTime.picking'
    ]
  },

  'averageActivePickingPercentage.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const activeTime = results['activePickTime.picking'] || 0;
        const missionTime = results['averageMissionTimeSec.picking'] || 1;
        return safeDivide(activeTime, missionTime);
      } catch (error) {
        console.log('Error in averageActivePickingPercentage.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates percentage of time spent actively picking',
    calculation: 'percentage = activePickTime / averageMissionTimeSec',
    dependencies: ['activePickTime.picking', 'averageMissionTimeSec.picking']
  },

  'averageMissionTimeMin.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        return safeDivide(results['averageMissionTimeSec.picking'] || 0, 60);
      } catch (error) {
        console.log('Error in averageMissionTimeMin.picking calculation:', error);
        return 0;
      }
    },
    description: 'Converts average mission time from seconds to minutes',
    calculation: 'missionTimeMin = averageMissionTimeSec / 60',
    dependencies: ['averageMissionTimeSec.picking']
  },

  'robotJobsPerHour.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        return safeDivide(60, results['averageMissionTimeMin.picking'] || 1);
      } catch (error) {
        console.log('Error in robotJobsPerHour.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of jobs a robot can complete per hour',
    calculation: 'jobsPerHour = 60 / averageMissionTimeMin',
    dependencies: ['averageMissionTimeMin.picking']
  },

  'totalJobsPerHourRequired.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        const linesPerMission = results['linesPerMission.picking'] || 1;
        const productiveHours = userInput?.productiveHoursPerDayPicking || 16; // Default to 16 hours
        
        console.log('Jobs per hour calculation:', {
          linesPerDay,
          linesPerMission,
          productiveHours
        });
        
        return (linesPerDay / linesPerMission) / productiveHours;
      } catch (error) {
        console.log('Error in totalJobsPerHourRequired.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total jobs per hour needed to meet demand',
    calculation: '(linesPerDay / linesPerMission) / productiveHoursPerDay',
    dependencies: ['designVolumes.linesPerDay.picking', 'linesPerMission.picking', 'productiveHoursPerDayPicking']
  },

  // Robot Utilization Calculations
  'averageAvailableTime.picking': {
    compute: (userInput) => {
      try {
        return safeMultiply(userInput?.productiveHoursPerDayPicking || 0, 3600);
      } catch (error) {
        console.log('Error in averageAvailableTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates average available time in seconds per day',
    calculation: 'availableTime = productiveHoursPerDay × 3600',
    dependencies: ['productiveHoursPerDayPicking']
  },

  'activeRobots.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const jobsPerHourRequired = results['totalJobsPerHourRequired.picking'] || 0;
        const robotJobsPerHour = results['robotJobsPerHour.picking'] || 1;
        return Math.ceil(safeDivide(jobsPerHourRequired, robotJobsPerHour));
      } catch (error) {
        console.log('Error in activeRobots.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of active robots needed',
    calculation: 'activeRobots = CEILING(totalJobsPerHourRequired / robotJobsPerHour)',
    dependencies: ['totalJobsPerHourRequired.picking', 'robotJobsPerHour.picking']
  },

  'chargingBuffer.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        return Math.ceil(safeMultiply(results['activeRobots.picking'] || 0, 0.15));
      } catch (error) {
        console.log('Error in chargingBuffer.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of additional robots needed for charging buffer',
    calculation: 'chargingBuffer = CEILING(activeRobots × 0.15)',
    dependencies: ['activeRobots.picking']
  },

  'totalRobots.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const activeRobots = results['activeRobots.picking'] || 0;
        const chargingBuffer = results['chargingBuffer.picking'] || 0;
        return activeRobots + chargingBuffer;
      } catch (error) {
        console.log('Error in totalRobots.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total number of robots needed including charging buffer',
    calculation: 'totalRobots = activeRobots + chargingBuffer',
    dependencies: ['activeRobots.picking', 'chargingBuffer.picking']
  },

  // Performance Metrics
  'activeRobotLphDesignVol.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        return safeMultiply(
          results['linesPerMission.picking'] || 0,
          results['robotJobsPerHour.picking'] || 0
        );
      } catch (error) {
        console.log('Error in activeRobotLphDesignVol.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates lines per hour per active robot at design volume',
    calculation: 'lph = linesPerMission × robotJobsPerHour',
    dependencies: ['linesPerMission.picking', 'robotJobsPerHour.picking']
  },

  'allRobotLphDesignVol.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        return safeMultiply(
          results['activeRobots.picking'] || 0,
          results['activeRobotLphDesignVol.picking'] || 0
        );
      } catch (error) {
        console.log('Error in allRobotLphDesignVol.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total lines per hour for all active robots',
    calculation: 'totalLph = activeRobots × activeRobotLphDesignVol',
    dependencies: ['activeRobots.picking', 'activeRobotLphDesignVol.picking']
  },

  // FTE Calculations
  'designDirectFtes.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const totalJobsPerHour = results['totalJobsPerHourRequired.picking'] || 0;
        const productiveHours = userInput?.productiveHoursPerDayPicking || 1;
        const fteHoursPerDay = staticInputs?.fteHoursPerDay || 8;
        const efficiencyFactors = safeLookup(getLookupValue, 'fteEfficiencyFactors') || {};
        const efficiencyFactor = efficiencyFactors[userInput?.fteEfficiencyMode || 'standard'] || 1.0;
        
        const numerator = safeMultiply(totalJobsPerHour, productiveHours);
        const denominator = safeMultiply(
          staticInputs?.jobsPerHourPerFte || 1,
          fteHoursPerDay,
          efficiencyFactor
        );
        
        return safeDivide(numerator, denominator);
      } catch (error) {
        console.log('Error in designDirectFtes.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of direct FTEs needed',
    calculation: 'directFtes = (totalJobsPerHour × productiveHours) / (jobsPerHourPerFte × fteHoursPerDay × efficiencyFactor)',
    dependencies: ['totalJobsPerHourRequired.picking', 'productiveHoursPerDayPicking', 'fteEfficiencyMode']
  },

  'designIndirectFtes.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        return safeMultiply(
          results['designDirectFtes.picking'] || 0,
          staticInputs?.indirectFtePercentage || 0
        );
      } catch (error) {
        console.log('Error in designIndirectFtes.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of indirect FTEs needed',
    calculation: 'indirectFtes = directFtes × indirectFtePercentage',
    dependencies: ['designDirectFtes.picking']
  },

  'designInductOperators.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const totalJobsPerHour = results['totalJobsPerHourRequired.picking'] || 0;
        const productiveHours = userInput?.productiveHoursPerDayPicking || 1;
        const fteHoursPerDay = staticInputs?.fteHoursPerDay || 8;
        
        const numerator = safeMultiply(totalJobsPerHour, productiveHours);
        const denominator = safeMultiply(
          staticInputs?.inductJobsPerHourPerFte || 1,
          fteHoursPerDay
        );
        
        return safeDivide(numerator, denominator);
      } catch (error) {
        console.log('Error in designInductOperators.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of induct operators needed',
    calculation: 'inductOperators = (totalJobsPerHour × productiveHours) / (inductJobsPerHourPerFte × fteHoursPerDay)',
    dependencies: ['totalJobsPerHourRequired.picking', 'productiveHoursPerDayPicking']
  },

  'designDropOffOperators.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const totalJobsPerHour = results['totalJobsPerHourRequired.picking'] || 0;
        const productiveHours = userInput?.productiveHoursPerDayPicking || 1;
        const fteHoursPerDay = staticInputs?.fteHoursPerDay || 8;
        
        const numerator = safeMultiply(totalJobsPerHour, productiveHours);
        const denominator = safeMultiply(
          staticInputs?.dropoffJobsPerHourPerFte || 1,
          fteHoursPerDay
        );
        
        return safeDivide(numerator, denominator);
      } catch (error) {
        console.log('Error in designDropOffOperators.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of dropoff operators needed',
    calculation: 'dropOffOperators = (totalJobsPerHour × productiveHours) / (dropoffJobsPerHourPerFte × fteHoursPerDay)',
    dependencies: ['totalJobsPerHourRequired.picking', 'productiveHoursPerDayPicking']
  },

  // Travel Time Calculations
  'travelToFirstPickTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const robotSpeed = results['robotSpeed.picking'] || 1;
        const distance = userInput?.distanceToFirstPick?.picking || 0;
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        console.log('Error in travelToFirstPickTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates time to travel to first pick location',
    calculation: 'travelTime = distance / robotSpeed',
    dependencies: ['robotSpeed.picking', 'distanceToFirstPick.picking']
  },

  'travelToNextPickTotalTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const robotSpeed = results['robotSpeed.picking'] || 1;
        const distance = results['travelToNextPickDistance.picking'] || 0;
        const linesPerMission = results['linesPerMission.picking'] || 1;
        
        // Subtract 1 from linesPerMission because we don't need to travel after the last pick
        const numberOfTravels = Math.max(0, linesPerMission - 1);
        
        return safeMultiply(safeDivide(distance, robotSpeed), numberOfTravels);
      } catch (error) {
        console.log('Error in travelToNextPickTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time spent traveling between pick locations',
    calculation: 'totalTime = (distance / robotSpeed) × (linesPerMission - 1)',
    dependencies: ['robotSpeed.picking', 'travelToNextPickDistance.picking', 'linesPerMission.picking']
  },

  'travelToDropoffTotalTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const robotSpeed = results['robotSpeed.picking'] || 1;
        const distance = userInput?.distanceToDropoff?.picking || 0;
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        console.log('Error in travelToDropoffTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates time to travel to dropoff location',
    calculation: 'travelTime = distance / robotSpeed',
    dependencies: ['robotSpeed.picking', 'distanceToDropoff.picking']
  },

  'travelToInductTotalTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const robotSpeed = results['robotSpeed.picking'] || 1;
        const distance = userInput?.distanceToInduct?.picking || 0;
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        console.log('Error in travelToInductTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates time to travel to induct location',
    calculation: 'travelTime = distance / robotSpeed',
    dependencies: ['robotSpeed.picking', 'distanceToInduct.picking']
  },

  // Queue Time Calculations
  'dropoffQueueTotalTime.picking': {
    compute: (userInput, staticInputs) => {
      try {
        return userInput?.designVolumes?.dropoffQueueTime?.picking || 0;
      } catch (error) {
        console.log('Error in dropoffQueueTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets the total queue time at dropoff',
    calculation: 'Direct value from designVolumes.dropoffQueueTime.picking',
    dependencies: ['designVolumes.dropoffQueueTime.picking']
  },

  'inductQueueTotalTime.picking': {
    compute: (userInput, staticInputs) => {
      try {
        return userInput?.designVolumes?.inductQueueTime?.picking || 0;
      } catch (error) {
        console.log('Error in inductQueueTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets the total queue time at induct',
    calculation: 'Direct value from designVolumes.inductQueueTime.picking',
    dependencies: ['designVolumes.inductQueueTime.picking']
  },

  'dropoffTotalTime.picking': {
    compute: (userInput) => {
      try {
        return userInput?.designVolumes?.dropoffUnloadTime?.picking || 0;
      } catch (error) {
        console.log('Error in dropoffTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets the total time for dropoff unloading process',
    calculation: 'Direct value from designVolumes.dropoffUnloadTime.picking',
    dependencies: ['designVolumes.dropoffUnloadTime.picking']
  },

  'autonomousUnloadTime.picking': {
    compute: (userInput, staticInputs) => {
      try {
        return staticInputs?.autonomousUnloadTime || 0;
      } catch (error) {
        console.log('Error in autonomousUnloadTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets the autonomous unload time from static inputs',
    calculation: 'Direct value from staticInputs.autonomousUnloadTime',
    dependencies: []
  },

  // Induct to Pick Area Calculations
  'inductToPickArea.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const inductToPickArea = staticInputs?.inductToPickArea || 0;
        
        if (isImperial) {
          return inductToPickArea;
        } else {
          return convertImperialToMetric(inductToPickArea, 'ft', 'm');
        }
      } catch (error) {
        console.log('Error in inductToPickArea.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates induct to pick area distance with unit conversion',
    calculation: '=IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),""))',
    dependencies: ['metricOrImperial', 'staticInputs.inductToPickArea']
  },

  'inductToPickArea.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const inductToPickArea = staticInputs?.inductToPickArea || 0;
        
        if (isImperial) {
          return inductToPickArea;
        } else {
          return convertImperialToMetric(inductToPickArea, 'ft', 'm');
        }
      } catch (error) {
        console.log('Error in inductToPickArea.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates induct to pick area distance with unit conversion',
    calculation: '=IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),""))',
    dependencies: ['metricOrImperial', 'staticInputs.inductToPickArea']
  },

  // Travel to First Pick Distance Calculations
  'travelToFirstPickDistance.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // =IFERROR(XLOOKUP(C106,B77:B80,C77:C80,"",0)*C107,"")
        const locationEntry = results?.inductDropoffLocationEntry?.picking || '';
        const inductToPickArea = results?.inductToPickArea?.picking || 0;
        const locationFactor = lookupReference?.locationFactors?.[locationEntry] || 0;
        
        return locationFactor * inductToPickArea;
      } catch (error) {
        console.log('Error in travelToFirstPickDistance.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to first pick location',
    calculation: '=IFERROR(XLOOKUP(C106,B77:B80,C77:C80,"",0)*C107,"")',
    dependencies: ['inductDropoffLocationEntry.picking', 'inductToPickArea.picking', 'lookupReference.locationFactors']
  },

  'travelToFirstPickDistance.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // =IFERROR(XLOOKUP(D106,B77:B80,C77:C80,"",0)*D107,"")
        const locationEntry = results?.inductDropoffLocationEntry?.putaway || '';
        const inductToPickArea = results?.inductToPickArea?.putaway || 0;
        const locationFactor = lookupReference?.locationFactors?.[locationEntry] || 0;
        
        return locationFactor * inductToPickArea;
      } catch (error) {
        console.log('Error in travelToFirstPickDistance.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to first pick location',
    calculation: '=IFERROR(XLOOKUP(D106,B77:B80,C77:C80,"",0)*D107,"")',
    dependencies: ['inductDropoffLocationEntry.putaway', 'inductToPickArea.putaway', 'lookupReference.locationFactors']
  },

  // Travel to First Pick Time Calculations
  'travelToFirstPickTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // =IFERROR(C108/C101,"")
        const distance = results?.travelToFirstPickDistance?.picking || 0;
        const robotSpeed = results?.robotSpeed?.picking || 1; // Avoid division by zero
        
        return distance / robotSpeed;
      } catch (error) {
        console.log('Error in travelToFirstPickTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel time to first pick location',
    calculation: '=IFERROR(C108/C101,"")',
    dependencies: ['travelToFirstPickDistance.picking', 'robotSpeed.picking']
  },

  'travelToFirstPickTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // =IFERROR(D108/D101,"")
        const distance = results?.travelToFirstPickDistance?.putaway || 0;
        const robotSpeed = results?.robotSpeed?.putaway || 1; // Avoid division by zero
        
        return distance / robotSpeed;
      } catch (error) {
        console.log('Error in travelToFirstPickTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel time to first pick location',
    calculation: '=IFERROR(D108/D101,"")',
    dependencies: ['travelToFirstPickDistance.putaway', 'robotSpeed.putaway']
  },

  // Total Time Per Line Calculations
  'totalTimePerLine.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C121 = IFERROR(C111+C112+C115+C120,"")
        const inductTotalTime = results['inductTotalTime.picking'] || 0;
        const travelToFirstPickTime = results['travelToFirstPickTime.picking'] || 0;
        const pickLineTotalTime = results['pickLineTotalTime.picking'] || 0;
        const travelToNextPickTime = results['travelToNextPickTime.picking'] || 0;
        
        return inductTotalTime + travelToFirstPickTime + pickLineTotalTime + travelToNextPickTime;
      } catch (error) {
        console.log('Error in totalTimePerLine.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time per line for picking',
    calculation: 'inductTotalTime + travelToFirstPickTime + pickLineTotalTime + travelToNextPickTime',
    dependencies: [
      'inductTotalTime.picking',
      'travelToFirstPickTime.picking',
      'pickLineTotalTime.picking',
      'travelToNextPickTime.picking'
    ]
  },

  'totalTimePerLine.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D121 = IFERROR(D111+D112+D115+D120,"")
        const inductTotalTime = results['inductTotalTime.putaway'] || 0;
        const travelToFirstPickTime = results['travelToFirstPickTime.putaway'] || 0;
        const pickLineTotalTime = results['pickLineTotalTime.putaway'] || 0;
        const travelToNextPickTime = results['travelToNextPickTime.putaway'] || 0;
        
        return inductTotalTime + travelToFirstPickTime + pickLineTotalTime + travelToNextPickTime;
      } catch (error) {
        console.log('Error in totalTimePerLine.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time per line for putaway',
    calculation: 'inductTotalTime + travelToFirstPickTime + pickLineTotalTime + travelToNextPickTime',
    dependencies: [
      'inductTotalTime.putaway',
      'travelToFirstPickTime.putaway',
      'pickLineTotalTime.putaway',
      'travelToNextPickTime.putaway'
    ]
  },

  // Total Time Per Mission Calculations
  'totalTimePerMission.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C122 = IFERROR(C121*C102,"")
        const totalTimePerLine = results['totalTimePerLine.picking'] || 0;
        const linesPerMission = results['linesPerMission.picking'] || 0;
        return safeMultiply(totalTimePerLine, linesPerMission);
      } catch (error) {
        console.log('Error in totalTimePerMission.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time per mission for picking',
    calculation: 'totalTimePerLine × linesPerMission',
    dependencies: ['totalTimePerLine.picking', 'linesPerMission.picking']
  },

  'totalTimePerMission.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D122 = IFERROR(D121*D102,"")
        const totalTimePerLine = results['totalTimePerLine.putaway'] || 0;
        const linesPerMission = results['linesPerMission.putaway'] || 0;
        return safeMultiply(totalTimePerLine, linesPerMission);
      } catch (error) {
        console.log('Error in totalTimePerMission.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time per mission for putaway',
    calculation: 'totalTimePerLine × linesPerMission',
    dependencies: ['totalTimePerLine.putaway', 'linesPerMission.putaway']
  },

  // Total Time Per Day Calculations
  'totalTimePerDay.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C123 = IFERROR(C122*C101,"")
        const totalTimePerMission = results['totalTimePerMission.picking'] || 0;
        const missionsPerDay = results['missionsPerDay.picking'] || 0;
        return safeMultiply(totalTimePerMission, missionsPerDay);
      } catch (error) {
        console.log('Error in totalTimePerDay.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time per day for picking',
    calculation: 'totalTimePerMission × missionsPerDay',
    dependencies: ['totalTimePerMission.picking', 'missionsPerDay.picking']
  },

  'totalTimePerDay.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D123 = IFERROR(D122*D101,"")
        const totalTimePerMission = results['totalTimePerMission.putaway'] || 0;
        const missionsPerDay = results['missionsPerDay.putaway'] || 0;
        return safeMultiply(totalTimePerMission, missionsPerDay);
      } catch (error) {
        console.log('Error in totalTimePerDay.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time per day for putaway',
    calculation: 'totalTimePerMission × missionsPerDay',
    dependencies: ['totalTimePerMission.putaway', 'missionsPerDay.putaway']
  },

  // Robot Utilization Calculations
  'robotUtilization.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C124 = IFERROR(C123/(C100*3600),"")
        const totalTimePerDay = results['totalTimePerDay.picking'] || 0;
        const robotsRequired = results['robotsRequired.picking'] || 1;
        // Convert hours to seconds (3600 seconds per hour)
        return safeDivide(totalTimePerDay, safeMultiply(robotsRequired, 3600));
      } catch (error) {
        console.log('Error in robotUtilization.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates robot utilization percentage for picking',
    calculation: 'totalTimePerDay / (robotsRequired × 3600)',
    dependencies: ['totalTimePerDay.picking', 'robotsRequired.picking']
  },

  'robotUtilization.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D124 = IFERROR(D123/(D100*3600),"")
        const totalTimePerDay = results['totalTimePerDay.putaway'] || 0;
        const robotsRequired = results['robotsRequired.putaway'] || 1;
        // Convert hours to seconds (3600 seconds per hour)
        return safeDivide(totalTimePerDay, safeMultiply(robotsRequired, 3600));
      } catch (error) {
        console.log('Error in robotUtilization.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates robot utilization percentage for putaway',
    calculation: 'totalTimePerDay / (robotsRequired × 3600)',
    dependencies: ['totalTimePerDay.putaway', 'robotsRequired.putaway']
  },

  // Output Calculations
  'totalLinesPerDay.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C125 = IFERROR(C101*C102,"")
        const missionsPerDay = results['missionsPerDay.picking'] || 0;
        const linesPerMission = results['linesPerMission.picking'] || 0;
        return safeMultiply(missionsPerDay, linesPerMission);
      } catch (error) {
        console.log('Error in totalLinesPerDay.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total lines per day for picking',
    calculation: 'missionsPerDay × linesPerMission',
    dependencies: ['missionsPerDay.picking', 'linesPerMission.picking']
  },

  'totalLinesPerDay.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D125 = IFERROR(D101*D102,"")
        const missionsPerDay = results['missionsPerDay.putaway'] || 0;
        const linesPerMission = results['linesPerMission.putaway'] || 0;
        return safeMultiply(missionsPerDay, linesPerMission);
      } catch (error) {
        console.log('Error in totalLinesPerDay.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total lines per day for putaway',
    calculation: 'missionsPerDay × linesPerMission',
    dependencies: ['missionsPerDay.putaway', 'linesPerMission.putaway']
  },

  'totalUnitsPerDay.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C126 = IFERROR(C125*C104,"")
        const totalLinesPerDay = results['totalLinesPerDay.picking'] || 0;
        const unitsPerLine = results['unitsPerLine.picking'] || 0;
        return safeMultiply(totalLinesPerDay, unitsPerLine);
      } catch (error) {
        console.log('Error in totalUnitsPerDay.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total units per day for picking',
    calculation: 'totalLinesPerDay × unitsPerLine',
    dependencies: ['totalLinesPerDay.picking', 'unitsPerLine.picking']
  },

  'totalUnitsPerDay.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D126 = IFERROR(D125*D104,"")
        const totalLinesPerDay = results['totalLinesPerDay.putaway'] || 0;
        const unitsPerLine = results['unitsPerLine.putaway'] || 0;
        return safeMultiply(totalLinesPerDay, unitsPerLine);
      } catch (error) {
        console.log('Error in totalUnitsPerDay.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total units per day for putaway',
    calculation: 'totalLinesPerDay × unitsPerLine',
    dependencies: ['totalLinesPerDay.putaway', 'unitsPerLine.putaway']
  },

  'totalCasesPerDay.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C127 = IFERROR(C125*C105,"")
        const totalLinesPerDay = results['totalLinesPerDay.picking'] || 0;
        const casesPerLine = results['casesPerLine.picking'] || 0;
        return safeMultiply(totalLinesPerDay, casesPerLine);
      } catch (error) {
        console.log('Error in totalCasesPerDay.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total cases per day for picking',
    calculation: 'totalLinesPerDay × casesPerLine',
    dependencies: ['totalLinesPerDay.picking', 'casesPerLine.picking']
  },

  'totalCasesPerDay.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D127 = IFERROR(D125*D105,"")
        const totalLinesPerDay = results['totalLinesPerDay.putaway'] || 0;
        const casesPerLine = results['casesPerLine.putaway'] || 0;
        return safeMultiply(totalLinesPerDay, casesPerLine);
      } catch (error) {
        console.log('Error in totalCasesPerDay.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total cases per day for putaway',
    calculation: 'totalLinesPerDay × casesPerLine',
    dependencies: ['totalLinesPerDay.putaway', 'casesPerLine.putaway']
  },

  'totalPalletsPerDay.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C128 = IFERROR(C127/C107,"")
        const totalCasesPerDay = results['totalCasesPerDay.picking'] || 0;
        const casesPerPallet = results['casesPerPallet.picking'] || 1;
        return safeDivide(totalCasesPerDay, casesPerPallet);
      } catch (error) {
        console.log('Error in totalPalletsPerDay.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total pallets per day for picking',
    calculation: 'totalCasesPerDay / casesPerPallet',
    dependencies: ['totalCasesPerDay.picking', 'casesPerPallet.picking']
  },

  'totalPalletsPerDay.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D128 = IFERROR(D127/D107,"")
        const totalCasesPerDay = results['totalCasesPerDay.putaway'] || 0;
        const casesPerPallet = results['casesPerPallet.putaway'] || 1;
        return safeDivide(totalCasesPerDay, casesPerPallet);
      } catch (error) {
        console.log('Error in totalPalletsPerDay.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total pallets per day for putaway',
    calculation: 'totalCasesPerDay / casesPerPallet',
    dependencies: ['totalCasesPerDay.putaway', 'casesPerPallet.putaway']
  },

  'totalLinesPerHour.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // C129 = IFERROR(C125/C108,"")
        const totalLinesPerDay = results['totalLinesPerDay.picking'] || 0;
        const hoursPerDay = results['hoursPerDay.picking'] || 1;
        return safeDivide(totalLinesPerDay, hoursPerDay);
      } catch (error) {
        console.log('Error in totalLinesPerHour.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total lines per hour for picking',
    calculation: 'totalLinesPerDay / hoursPerDay',
    dependencies: ['totalLinesPerDay.picking', 'hoursPerDay.picking']
  },

  'totalLinesPerHour.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // D129 = IFERROR(D125/D108,"")
        const totalLinesPerDay = results['totalLinesPerDay.putaway'] || 0;
        const hoursPerDay = results['hoursPerDay.putaway'] || 1;
        return safeDivide(totalLinesPerDay, hoursPerDay);
      } catch (error) {
        console.log('Error in totalLinesPerHour.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total lines per hour for putaway',
    calculation: 'totalLinesPerDay / hoursPerDay',
    dependencies: ['totalLinesPerDay.putaway', 'hoursPerDay.putaway']
  },

  // Travel to Dropoff Distance
  'travelToDropoffDistance.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate dropoff distance using location factor and adjustment
        const locationEntry = results?.inductDropoffLocationEntry?.picking || '';
        const adjustment = results?.pickAreaToDropoffAdjustment?.picking || 0;
        const locationFactor = lookupReference?.locationFactors?.[locationEntry] || 0;
        
        return locationFactor * adjustment;
      } catch (error) {
        console.log('Error in travelToDropoffDistance.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to dropoff location using location factor and adjustment',
    calculation: 'locationFactor × pickAreaToDropoffAdjustment',
    dependencies: ['inductDropoffLocationEntry.picking', 'pickAreaToDropoffAdjustment.picking', 'lookupReference.locationFactors']
  },

  'travelToDropoffDistance.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate dropoff distance using location factor and adjustment
        const locationEntry = results?.inductDropoffLocationEntry?.putaway || '';
        const adjustment = results?.pickAreaToDropoffAdjustment?.putaway || 0;
        const locationFactor = lookupReference?.locationFactors?.[locationEntry] || 0;
        
        return locationFactor * adjustment;
      } catch (error) {
        console.log('Error in travelToDropoffDistance.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to dropoff location using location factor and adjustment',
    calculation: 'locationFactor × pickAreaToDropoffAdjustment',
    dependencies: ['inductDropoffLocationEntry.putaway', 'pickAreaToDropoffAdjustment.putaway', 'lookupReference.locationFactors']
  },

  // Travel to Dropoff Total Time
  'travelToDropoffTotalTime.picking': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate time based on distance and robot speed
        const distance = results?.travelToDropoffDistance?.picking || 0;
        const robotSpeed = results?.robotSpeed?.picking || 1; // Avoid division by zero
        return distance / robotSpeed;
      } catch (error) {
        console.log('Error in travelToDropoffTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel time to dropoff based on distance and robot speed',
    calculation: 'travelToDropoffDistance / robotSpeed',
    dependencies: ['travelToDropoffDistance.picking', 'robotSpeed.picking']
  },

  'travelToDropoffTotalTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate time based on distance and robot speed
        const distance = results?.travelToDropoffDistance?.putaway || 0;
        const robotSpeed = results?.robotSpeed?.putaway || 1; // Avoid division by zero
        return distance / robotSpeed;
      } catch (error) {
        console.log('Error in travelToDropoffTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel time to dropoff based on distance and robot speed',
    calculation: 'travelToDropoffDistance / robotSpeed',
    dependencies: ['travelToDropoffDistance.putaway', 'robotSpeed.putaway']
  },

  // Dropoff Queue Total Time
  'dropoffQueueTotalTime.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // Get dropoff queue time from static inputs
        return staticInputs?.dropoffQueueTotalTime || 0;
      } catch (error) {
        console.log('Error in dropoffQueueTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets dropoff queue total time from static inputs',
    calculation: 'Direct value from staticInputs.dropoffQueueTotalTime',
    dependencies: ['staticInputs.dropoffQueueTotalTime']
  },

  'dropoffQueueTotalTime.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // Get dropoff queue time from static inputs
        return staticInputs?.dropoffQueueTotalTime || 0;
      } catch (error) {
        console.log('Error in dropoffQueueTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets dropoff queue total time from static inputs',
    calculation: 'Direct value from staticInputs.dropoffQueueTotalTime',
    dependencies: ['staticInputs.dropoffQueueTotalTime']
  },

  // Dropoff Total Time
  'dropoffTotalTime.picking': {
    compute: (userInput) => {
      try {
        // Get dropoff total time from design volumes
        return userInput?.designVolumes?.dropOffUnloadTime?.picking || 0;
      } catch (error) {
        console.log('Error in dropoffTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets dropoff total time from design volumes',
    calculation: 'Direct value from designVolumes.dropOffUnloadTime.picking',
    dependencies: ['designVolumes.dropOffUnloadTime.picking']
  },

  'dropoffTotalTime.putaway': {
    compute: (userInput) => {
      try {
        // Get dropoff total time from design volumes
        return userInput?.designVolumes?.dropOffUnloadTime?.putaway || 0;
      } catch (error) {
        console.log('Error in dropoffTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets dropoff total time from design volumes',
    calculation: 'Direct value from designVolumes.dropOffUnloadTime.putaway',
    dependencies: ['designVolumes.dropOffUnloadTime.putaway']
  },

  // Autonomous Unload Time
  'autonomousUnloadTime.picking': {
    compute: (userInput, staticInputs) => {
      try {
        // Get autonomous unload time from static inputs
        return staticInputs?.autonomousUnloadTime || 0;
      } catch (error) {
        console.log('Error in autonomousUnloadTime.picking calculation:', error);
        return 0;
      }
    },
    description: 'Gets autonomous unload time from static inputs',
    calculation: 'Direct value from staticInputs.autonomousUnloadTime',
    dependencies: ['staticInputs.autonomousUnloadTime']
  },

  'autonomousUnloadTime.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        // Get autonomous unload time from static inputs
        return staticInputs?.autonomousUnloadTime || 0;
      } catch (error) {
        console.log('Error in autonomousUnloadTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Gets autonomous unload time from static inputs',
    calculation: 'Direct value from staticInputs.autonomousUnloadTime',
    dependencies: ['staticInputs.autonomousUnloadTime']
},

  // Induct Dropoff Location Entry
  'inductDropoffLocationEntry.picking': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const robotType = (userInput?.robotOrigin || '').split(' ')[0].toLowerCase();
        const locationEntryTime = lookupReference?.robotSpecs?.locationEntry?.[robotType] || 0;
        return locationEntryTime;
      } catch (error) {
        console.log('Error in inductDropoffLocationEntry.picking calculation:', error);
        return 0;
      }
    },
    description: 'Determines location entry time based on robot type',
    calculation: 'Lookup location entry time from robot specs',
    dependencies: ['robotOrigin', 'lookupReference.robotSpecs']
  },

  'inductDropoffLocationEntry.putaway': {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const robotType = (userInput?.robotOrigin || '').split(' ')[0].toLowerCase();
        const locationEntryTime = lookupReference?.robotSpecs?.locationEntry?.[robotType] || 0;
        return locationEntryTime;
      } catch (error) {
        console.log('Error in inductDropoffLocationEntry.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Determines location entry time based on robot type',
    calculation: 'Lookup location entry time from robot specs',
    dependencies: ['robotOrigin', 'lookupReference.robotSpecs']
  },

  // Travel to Induct Distance
  'travelToInductDistance.picking': {
    compute: (userInput, staticInputs) => {
      try {
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const distance = userInput?.layoutDimensions?.travelToInductDistance || 0;
        return isImperial ? convertImperialToMetric(distance, 'ft', 'm') : distance;
      } catch (error) {
        console.log('Error in travelToInductDistance.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to induct location',
    calculation: 'Convert travel distance to metric if needed',
    dependencies: ['metricOrImperial', 'layoutDimensions.travelToInductDistance']
  },

  'travelToInductDistance.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        const isImperial = userInput?.metricOrImperial === 'Imperial';
        const distance = userInput?.layoutDimensions?.travelToInductDistance || 0;
        return isImperial ? convertImperialToMetric(distance, 'ft', 'm') : distance;
      } catch (error) {
        console.log('Error in travelToInductDistance.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates travel distance to induct location',
    calculation: 'Convert travel distance to metric if needed',
    dependencies: ['metricOrImperial', 'layoutDimensions.travelToInductDistance']
  },

  // Travel to Induct Total Time
  'travelToInductTotalTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const distance = getNestedValue(botCalculations, 'travelToInductDistance.putaway') || 0;
        const speed = getNestedValue(botCalculations, 'robotSpeed.putaway') || 0;
        return speed > 0 ? (distance / speed) : 0;
      } catch (error) {
        console.log('Error in travelToInductTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total time to travel to induct location',
    calculation: 'Travel distance / robot speed',
    dependencies: ['travelToInductDistance.putaway', 'robotSpeed.putaway']
  },

  // Active Pick Time
  'activePickTime.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const taskTime = userInput?.designVolumes?.taskTimePerLine?.putaway || 0;
        const linesPerMission = getNestedValue(botCalculations, 'linesPerMission.putaway') || 0;
        return taskTime * linesPerMission;
      } catch (error) {
        console.log('Error in activePickTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates active pick time for putaway',
    calculation: 'Task time per line * lines per mission',
    dependencies: ['designVolumes.taskTimePerLine.putaway', 'linesPerMission.putaway']
  },

  // Average Mission Time Sec
  'averageMissionTimeSec.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const components = {
          inductTime: getNestedValue(botCalculations, 'inductTotalTime.putaway') || 0,
          waitTime: getNestedValue(botCalculations, 'waitForFteTotalTime.putaway') || 0,
          pickTime: getNestedValue(botCalculations, 'activePickTime.putaway') || 0,
          travelNextTime: getNestedValue(botCalculations, 'travelToNextPickTotalTime.putaway') || 0,
          dropoffTime: getNestedValue(botCalculations, 'dropoffTotalTime.putaway') || 0,
          travelToInductTime: getNestedValue(botCalculations, 'travelToInductTotalTime.putaway') || 0
        };
        
        console.log('Mission time components:', components);
        return Object.values(components).reduce((sum, time) => sum + time, 0);
      } catch (error) {
        console.log('Error in averageMissionTimeSec.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates average mission time in seconds',
    calculation: 'Sum of all mission time components',
    dependencies: [
      'inductTotalTime.putaway',
      'waitForFteTotalTime.putaway',
      'activePickTime.putaway',
      'travelToNextPickTotalTime.putaway',
      'dropoffTotalTime.putaway',
      'travelToInductTotalTime.putaway'
    ]
  },

  // Average Active Picking Percentage
  'averageActivePickingPercentage.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const activeTime = getNestedValue(botCalculations, 'activePickTime.putaway') || 0;
        const missionTime = getNestedValue(botCalculations, 'averageMissionTimeSec.putaway') || 0;
        return missionTime > 0 ? (activeTime / missionTime) * 100 : 0;
      } catch (error) {
        console.log('Error in averageActivePickingPercentage.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates percentage of mission time spent actively picking',
    calculation: '(Active pick time / Average mission time) * 100',
    dependencies: ['activePickTime.putaway', 'averageMissionTimeSec.putaway']
  },

  // Total Jobs Per Hour Required
  'totalJobsPerHourRequired.putaway': {
    compute: (userInput, staticInputs) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const linesPerMission = userInput?.designVolumes?.linesPerMission?.putaway || 1;
        const productiveHours = userInput?.productiveHoursPerDay || 16;
        
        console.log('Jobs per hour calculation:', {
          linesPerDay,
          linesPerMission,
          productiveHours
        });
        
        return productiveHours > 0 ? (linesPerDay / linesPerMission) / productiveHours : 0;
      } catch (error) {
        console.log('Error in totalJobsPerHourRequired.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates required jobs per hour based on daily volume',
    calculation: '(Lines per day / Lines per mission) / Productive hours per day',
    dependencies: ['designVolumes.linesPerDay.putaway', 'designVolumes.linesPerMission.putaway', 'productiveHoursPerDay']
  },

  // Average Robot Mission Distance
  'averageRobotMissionDistance.picking': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const toFirstPick = getNestedValue(botCalculations, 'travelToFirstPickDistance.picking') || 0;
        const betweenPicks = getNestedValue(botCalculations, 'travelToNextPickDistance.picking') || 0;
        const toDropoff = getNestedValue(botCalculations, 'travelToDropoffDistance.picking') || 0;
        const toInduct = getNestedValue(botCalculations, 'travelToInductDistance.picking') || 0;
        
        return toFirstPick + betweenPicks + toDropoff + toInduct;
      } catch (error) {
        console.log('Error in averageRobotMissionDistance.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total distance traveled in one mission',
    calculation: 'Sum of all travel distances in mission',
    dependencies: [
      'travelToFirstPickDistance.picking',
      'travelToNextPickDistance.picking',
      'travelToDropoffDistance.picking',
      'travelToInductDistance.picking'
    ]
  },

  'averageRobotMissionDistance.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const toFirstPick = getNestedValue(botCalculations, 'travelToFirstPickDistance.putaway') || 0;
        const betweenPicks = getNestedValue(botCalculations, 'travelToNextPickDistance.putaway') || 0;
        const toDropoff = getNestedValue(botCalculations, 'travelToDropoffDistance.putaway') || 0;
        const toInduct = getNestedValue(botCalculations, 'travelToInductDistance.putaway') || 0;
        
        return toFirstPick + betweenPicks + toDropoff + toInduct;
      } catch (error) {
        console.log('Error in averageRobotMissionDistance.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total distance traveled in one mission',
    calculation: 'Sum of all travel distances in mission',
    dependencies: [
      'travelToFirstPickDistance.putaway',
      'travelToNextPickDistance.putaway',
      'travelToDropoffDistance.putaway',
      'travelToInductDistance.putaway'
    ]
  },

  // Average Mission Time Min
  'averageMissionTimeMin.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const missionTimeSec = getNestedValue(botCalculations, 'averageMissionTimeSec.putaway') || 0;
        return missionTimeSec / 60;
      } catch (error) {
        console.log('Error in averageMissionTimeMin.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Converts average mission time from seconds to minutes',
    calculation: 'Average mission time seconds / 60',
    dependencies: ['averageMissionTimeSec.putaway']
  },

  // Robot Jobs Per Hour
  'robotJobsPerHour.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const missionTimeMin = getNestedValue(botCalculations, 'averageMissionTimeMin.putaway') || 0;
        return missionTimeMin > 0 ? 60 / missionTimeMin : 0;
      } catch (error) {
        console.log('Error in robotJobsPerHour.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates how many jobs a robot can complete per hour',
    calculation: '60 / Average mission time minutes',
    dependencies: ['averageMissionTimeMin.putaway']
  },

  // Average Available Time
  'averageAvailableTime.putaway': {
    compute: (userInput) => {
      try {
        const productiveHours = userInput?.productiveHoursPerDay || 16;
        return productiveHours * 3600; // Convert hours to seconds
      } catch (error) {
        console.log('Error in averageAvailableTime.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates available time in seconds per day',
    calculation: 'Productive hours per day * 3600',
    dependencies: ['productiveHoursPerDay']
  },

  // Active Robots
  'activeRobots.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const jobsRequired = getNestedValue(botCalculations, 'totalJobsPerHourRequired.putaway') || 0;
        const jobsPerRobot = getNestedValue(botCalculations, 'robotJobsPerHour.putaway') || 0;
        return jobsPerRobot > 0 ? Math.ceil(jobsRequired / jobsPerRobot) : 0;
      } catch (error) {
        console.log('Error in activeRobots.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of active robots needed',
    calculation: 'Ceiling(Total jobs required per hour / Robot jobs per hour)',
    dependencies: ['totalJobsPerHourRequired.putaway', 'robotJobsPerHour.putaway']
  },

  // Charging Buffer
  'chargingBuffer.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const activeRobots = getNestedValue(botCalculations, 'activeRobots.putaway') || 0;
        const bufferPercentage = userInput?.chargingBufferPercentage || 0.15;
        return Math.ceil(activeRobots * bufferPercentage);
      } catch (error) {
        console.log('Error in chargingBuffer.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of charging buffer robots needed',
    calculation: 'Ceiling(Active robots * Charging buffer percentage)',
    dependencies: ['activeRobots.putaway', 'chargingBufferPercentage']
  },

  // Charging Robots
  'chargingRobots.picking': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const activeRobots = getNestedValue(botCalculations, 'activeRobots.picking') || 0;
        const chargingBuffer = getNestedValue(botCalculations, 'chargingBuffer.picking') || 0;
        return activeRobots + chargingBuffer;
      } catch (error) {
        console.log('Error in chargingRobots.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total number of robots including charging buffer',
    calculation: 'Active robots + Charging buffer',
    dependencies: ['activeRobots.picking', 'chargingBuffer.picking']
  },

  'chargingRobots.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const activeRobots = getNestedValue(botCalculations, 'activeRobots.putaway') || 0;
        const chargingBuffer = getNestedValue(botCalculations, 'chargingBuffer.putaway') || 0;
        return activeRobots + chargingBuffer;
      } catch (error) {
        console.log('Error in chargingRobots.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates total number of robots including charging buffer',
    calculation: 'Active robots + Charging buffer',
    dependencies: ['activeRobots.putaway', 'chargingBuffer.putaway']
  },

  // Total Robots
  'totalRobots.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        return getNestedValue(botCalculations, 'chargingRobots.putaway') || 0;
      } catch (error) {
        console.log('Error in totalRobots.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Returns total number of robots needed',
    calculation: 'Equal to charging robots',
    dependencies: ['chargingRobots.putaway']
  },

  // Available Robots
  'availableRobots.picking': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const totalRobots = getNestedValue(botCalculations, 'totalRobots.picking') || 0;
        const chargingBuffer = getNestedValue(botCalculations, 'chargingBuffer.picking') || 0;
        return Math.max(0, totalRobots - chargingBuffer);
      } catch (error) {
        console.log('Error in availableRobots.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of robots available for work',
    calculation: 'Total robots - Charging buffer',
    dependencies: ['totalRobots.picking', 'chargingBuffer.picking']
  },

  'availableRobots.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const totalRobots = getNestedValue(botCalculations, 'totalRobots.putaway') || 0;
        const chargingBuffer = getNestedValue(botCalculations, 'chargingBuffer.putaway') || 0;
        return Math.max(0, totalRobots - chargingBuffer);
      } catch (error) {
        console.log('Error in availableRobots.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates number of robots available for work',
    calculation: 'Total robots - Charging buffer',
    dependencies: ['totalRobots.putaway', 'chargingBuffer.putaway']
  },

  // Active Robot Lines Per Hour Design Volume
  'activeRobotLphDesignVol.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const jobsPerHour = getNestedValue(botCalculations, 'robotJobsPerHour.putaway') || 0;
        const linesPerMission = getNestedValue(botCalculations, 'linesPerMission.putaway') || 0;
        return jobsPerHour * linesPerMission;
      } catch (error) {
        console.log('Error in activeRobotLphDesignVol.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates lines per hour per active robot',
    calculation: 'Robot jobs per hour * Lines per mission',
    dependencies: ['robotJobsPerHour.putaway', 'linesPerMission.putaway']
  },

  // Active Robot Units Per Hour Design Volume
  'activeRobotUphDesignVol.picking': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const lph = getNestedValue(botCalculations, 'activeRobotLphDesignVol.picking') || 0;
        const unitsPerLine = userInput?.designVolumes?.unitsPerLine?.picking || 1;
        return lph * unitsPerLine;
      } catch (error) {
        console.log('Error in activeRobotUphDesignVol.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates units per hour per active robot',
    calculation: 'Active robot LPH * Units per line',
    dependencies: ['activeRobotLphDesignVol.picking', 'designVolumes.unitsPerLine.picking']
  },

  'activeRobotUphDesignVol.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const lph = getNestedValue(botCalculations, 'activeRobotLphDesignVol.putaway') || 0;
        const unitsPerLine = userInput?.designVolumes?.unitsPerLine?.putaway || 1;
        return lph * unitsPerLine;
      } catch (error) {
        console.log('Error in activeRobotUphDesignVol.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates units per hour per active robot',
    calculation: 'Active robot LPH * Units per line',
    dependencies: ['activeRobotLphDesignVol.putaway', 'designVolumes.unitsPerLine.putaway']
  },

  // Design Lines Per Hour
  'designLinesPerHour.picking': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        const productiveHours = userInput?.productiveHoursPerDay || 16;
        return productiveHours > 0 ? linesPerDay / productiveHours : 0;
      } catch (error) {
        console.log('Error in designLinesPerHour.picking calculation:', error);
        return 0;
      }
    },
    description: 'Calculates required lines per hour based on daily volume',
    calculation: 'Lines per day / Productive hours per day',
    dependencies: ['designVolumes.linesPerDay.picking', 'productiveHoursPerDay']
  },

  'designLinesPerHour.putaway': {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const productiveHours = userInput?.productiveHoursPerDay || 16;
        return productiveHours > 0 ? linesPerDay / productiveHours : 0;
      } catch (error) {
        console.log('Error in designLinesPerHour.putaway calculation:', error);
        return 0;
      }
    },
    description: 'Calculates required lines per hour based on daily volume',
    calculation: 'Lines per day / Productive hours per day',
    dependencies: ['designVolumes.linesPerDay.putaway', 'productiveHoursPerDay']
  }
}; 

// Main calculation function with enhanced safety and dependency handling
export const calculateBotMetrics = (userInput, staticInputs, lookupReference) => {
  try {
    console.log('calculateBotMetrics called with:', {
      userInput,
      staticInputs,
      lookupReference
    });

    // Validate inputs
    if (!isValidObject(userInput)) {
      console.log('Invalid userInput object');
      return {
        values: {},
        metadata: { error: 'Invalid userInput object' }
      };
    }

    if (!isValidObject(staticInputs)) {
      console.log('Invalid staticInputs object');
      return {
        values: {},
        metadata: { error: 'Invalid staticInputs object' }
      };
    }

    // Initialize results with default values
    const results = {
      robot: { picking: '', putaway: '' },
      batchBot: { picking: 'FALSE', putaway: 'FALSE' },
      robotSpeed: { picking: 0, putaway: 0 },
      linesPerMission: { picking: 0, putaway: 0 },
      linesPerStop: { picking: 0, putaway: 0 },
      inductTotalTime: { picking: 0, putaway: 0 },
      autonomousLoadTime: { picking: 0, putaway: 0 },
      inductDropoffLocationEntry: { picking: '', putaway: '' },
      inductToPickArea: { picking: 0, putaway: 0 },
      travelToFirstPickDistance: { picking: 0, putaway: 0 },
      travelToFirstPickTime: { picking: 0, putaway: 0 },
      waitForFteTime: { picking: 0, putaway: 0 },
      waitForFteQty: { picking: 0, putaway: 0 },
      waitForFteTotalTime: { picking: 0, putaway: 0 },
      pickLineTime: { picking: 0, putaway: 0 },
      pickLineQty: { picking: 0, putaway: 0 },
      pickLineTotalTime: { picking: 0, putaway: 0 },
      avgTasksPerAisle: { picking: 0, putaway: 0 },
      avgJobPercentageOfZone: { picking: 0, putaway: 0 },
      navigationFactor: { picking: 0, putaway: 0 },
      travelToNextPickDistance: { picking: 0, putaway: 0 },
      travelToNextPickTime: { picking: 0, putaway: 0 },
      travelToNextPickQty: { picking: 0, putaway: 0 },
      travelToNextPickTotalTime: { picking: 0, putaway: 0 },
      pickAreaToDropoffAdjustment: { picking: 0, putaway: 0 },
      travelToDropoffDistance: { picking: 0, putaway: 0 },
      travelToDropoffTotalTime: { picking: 0, putaway: 0 },
      dropoffQueueTotalTime: { picking: 0, putaway: 0 },
      dropoffTotalTime: { picking: 0, putaway: 0 },
      autonomousUnloadTime: { picking: 0, putaway: 0 },
      travelToInductDistance: { picking: 0, putaway: 0 },
      travelToInductTotalTime: { picking: 0, putaway: 0 },
      inductQueueTotalTime: { picking: 0, putaway: 0 },
      activePickTime: { picking: 0, putaway: 0 },
      averageMissionTimeSec: { picking: 0, putaway: 0 },
      averageActivePickingPercentage: { picking: 0, putaway: 0 },
      totalJobsPerHourRequired: { picking: 0, putaway: 0 },
      averageRobotMissionDistance: { picking: 0, putaway: 0 },
      averageMissionTimeMin: { picking: 0, putaway: 0 },
      robotJobsPerHour: { picking: 0, putaway: 0 },
      averageAvailableTime: { picking: 0, putaway: 0 },
      activeRobots: { picking: 0, putaway: 0 },
      chargingBuffer: { picking: 0, putaway: 0 },
      chargingRobots: { picking: 0, putaway: 0 },
      totalRobots: { picking: 0, putaway: 0 },
      availableRobots: { picking: 0, putaway: 0 },
      activeRobotLphDesignVol: { picking: 0, putaway: 0 },
      activeRobotUphDesignVol: { picking: 0, putaway: 0 },
      designLinesPerHour: { picking: 0, putaway: 0 },
      allRobotLphDesignVol: { picking: 0, putaway: 0 },
      allRobotUphDesignVol: { picking: 0, putaway: 0 },
      walkToNewRobotDistance: { picking: 0, putaway: 0 },
      walkToNewBotSeconds: { picking: 0, putaway: 0 },
      repeatedAction: { picking: 'Location', putaway: 'Location' },
      repeatedActionSeconds: { picking: 0, putaway: 0 },
      linesPerCycle: { picking: 0, putaway: 0 },
      timePerCycleSeconds: { picking: 0, putaway: 0 },
      lph: { picking: 0, putaway: 0 },
      unitsPerLine: { picking: 0, putaway: 0 },
      uph: { picking: 0, putaway: 0 },
      designVolumeUnitsPerHour: { picking: 0, putaway: 0 },
      totalDesignFtes: { picking: 0, putaway: 0 },
      designVolumeWithFactorUnitsPerHour: { picking: 0, putaway: 0 },
      designDirectFtes: { picking: 0, putaway: 0 },
      designIndirectFtes: { picking: 0, putaway: 0 },
      designInductOperators: { picking: 0, putaway: 0 },
      designDropOffOperators: { picking: 0, putaway: 0 }
    };

    let iterations = 0;
    const MAX_ITERATIONS = 10;
    let hasChanges = true;
    let lastError = null;

    // Define calculation order based on dependencies
    const calculationOrder = [
      // Basic inputs and lookups first
      ['robot.picking', 'robot.putaway'],
      ['batchBot.picking', 'batchBot.putaway'],
      ['robotSpeed.picking', 'robotSpeed.putaway'],
      ['linesPerMission.picking', 'linesPerMission.putaway'],
      ['linesPerStop.picking', 'linesPerStop.putaway'],
      
      // Location and navigation factors
      ['inductDropoffLocationEntry.picking', 'inductDropoffLocationEntry.putaway'],
      ['navigationFactor.picking', 'navigationFactor.putaway'],
      
      // Distance calculations
      ['pickAreaToDropoffAdjustment.picking', 'pickAreaToDropoffAdjustment.putaway'],
      ['travelToDropoffDistance.picking', 'travelToDropoffDistance.putaway'],
      ['travelToInductDistance.picking', 'travelToInductDistance.putaway'],
      
      // Time calculations
      ['travelToDropoffTotalTime.picking', 'travelToDropoffTotalTime.putaway'],
      ['travelToInductTotalTime.picking', 'travelToInductTotalTime.putaway'],
      ['dropoffQueueTotalTime.picking', 'dropoffQueueTotalTime.putaway'],
      ['dropoffTotalTime.picking', 'dropoffTotalTime.putaway'],
      ['autonomousUnloadTime.picking', 'autonomousUnloadTime.putaway'],
      ['inductQueueTotalTime.picking', 'inductQueueTotalTime.putaway'],
      
      // Mission time and efficiency calculations
      ['activePickTime.picking', 'activePickTime.putaway'],
      ['averageMissionTimeSec.picking', 'averageMissionTimeSec.putaway'],
      ['averageActivePickingPercentage.picking', 'averageActivePickingPercentage.putaway'],
      
      // Robot requirements calculations
      ['totalJobsPerHourRequired.picking', 'totalJobsPerHourRequired.putaway'],
      ['averageRobotMissionDistance.picking', 'averageRobotMissionDistance.putaway'],
      ['averageMissionTimeMin.picking', 'averageMissionTimeMin.putaway'],
      ['robotJobsPerHour.picking', 'robotJobsPerHour.putaway'],
      ['averageAvailableTime.picking', 'averageAvailableTime.putaway'],
      ['activeRobots.picking', 'activeRobots.putaway'],
      
      // Final calculations
      ['chargingBuffer.picking', 'chargingBuffer.putaway'],
      ['chargingRobots.picking', 'chargingRobots.putaway'],
      ['totalRobots.picking', 'totalRobots.putaway'],
      ['availableRobots.picking', 'availableRobots.putaway'],
      ['activeRobotLphDesignVol.picking', 'activeRobotLphDesignVol.putaway'],
      ['activeRobotUphDesignVol.picking', 'activeRobotUphDesignVol.putaway'],
      ['designLinesPerHour.picking', 'designLinesPerHour.putaway']
    ];

    console.log('Starting calculation iterations');

    // Keep calculating until no changes or max iterations reached
    while (hasChanges && iterations < MAX_ITERATIONS) {
      hasChanges = false;
      iterations++;
      console.log(`Iteration ${iterations}`);

      // Process calculations in dependency order
      for (const calculationGroup of calculationOrder) {
        for (const key of calculationGroup) {
          const calculator = calculationMap[key];
          if (!isValidObject(calculator) || typeof calculator.compute !== 'function') {
            console.log(`Invalid calculator for ${key}`);
            continue;
          }

          const prevValue = getNestedValue(results, key);
          try {
            console.log(`Computing ${key}`);
            const newValue = calculator.compute(userInput, staticInputs, lookupReference, results);
            console.log(`${key} result:`, newValue);
            
            // Validate the new value based on expected type
            let isValidValue = true;
            if (key.startsWith('robot.') || key.startsWith('batchBot.') || key.startsWith('repeatedAction.')) {
              isValidValue = typeof newValue === 'string';
            } else {
              isValidValue = isValidNumber(newValue);
            }

            if (!isValidValue) {
              console.log(`Invalid value type for ${key}`);
              continue;
            }

            const currentValue = getNestedValue(results, key);
            if (JSON.stringify(currentValue) !== JSON.stringify(newValue)) {
              setNestedValue(results, key, newValue);
              hasChanges = true;
              console.log(`Updated ${key} from`, currentValue, 'to', newValue);
            }
          } catch (error) {
            console.log(`Error calculating ${key}:`, error);
            lastError = error;
            // Keep previous value on error
            setNestedValue(results, key, prevValue);
          }
        }
      }
    }

    console.log('Calculation complete. Final results:', results);

    return {
      values: results,
      metadata: {
        iterations,
        calculationDescriptions: Object.fromEntries(
          Object.entries(calculationMap)
            .filter(([_, calc]) => isValidObject(calc))
            .map(([key, calc]) => [
              key, 
              { 
                description: calc.description || '', 
                dependencies: Array.isArray(calc.dependencies) ? calc.dependencies : [],
                calculation: calc.calculation || ''
              }
            ])
        ),
        warnings: iterations >= MAX_ITERATIONS ? ['Maximum iterations reached'] : [],
        error: lastError?.message
      }
    };
  } catch (error) {
    console.log('Error in calculateBotMetrics:', error);
    return {
      values: {},
      metadata: {
        iterations: 0,
        calculationDescriptions: {},
        error: error.message,
        warnings: ['Fatal error in calculation']
      }
    };
  }
};