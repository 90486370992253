// third-party
import { FormattedMessage } from "react-intl";

// assets
import { LocusHomeIcon } from "assets/icons/LocusIcons.js";
import {
  CROSS_SITE_VIEWER,
  LOCUS_TENANT,
  VIEWER,
  SYSTEM_INTEGRATOR
} from "../utils/constants/user";

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
/** @type {LocusMenuGroup} */
const home = {
  id: "home",
  type: "group",
  view: ["locus", "client", "site"],
  children: [
    {
      id: "locus",
      title: <FormattedMessage id="global-overview" />,
      type: "item",
      url: "/locus",
      icon: LocusHomeIcon,
      breadcrumbs: true,
      view: ["locus"],
      allowedGroups: [LOCUS_TENANT],
    },
    {
      id: "client",
      title: <FormattedMessage id="client-overview" />,
      type: "item",
      url: "/client/:clientId/overview",
      icon: LocusHomeIcon,
      breadcrumbs: false,
      view: ["client"],
      allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, SYSTEM_INTEGRATOR],
    },
    // {
    //   id: "overview-dashboard",
    //   title: <FormattedMessage id="site-overview" />,
    //   type: "item",
    //   url: "overview-dashboard?site=:siteId",
    //   icon: LocusHomeIcon,
    //   view: ["site"],
    //   allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
    // },
    {
      id: "site-info",
      title: <FormattedMessage id="site-info" />,
      type: "item",
      url: "site-info?site=:siteId",
      breadcrumbs: true,
      icon: LocusHomeIcon,
      view: ["site"],
      allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
    },
  ],
};

export default home;
