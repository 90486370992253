import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";
import { calculateBotMetrics } from '../calculations/botCalculations';
import { getLookupValue, getLookupMetadata } from '../calculations/lookupReferences';
import { calculateOutputs } from '../calculations/outputCalculations';

const flattenSiteActionLogs = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.values?.taskType || "N/A",
    status: item?.values?.status || "N/A",
    submittedAt: item.createdAt || "N/A",
    completedAt: item.values?.completedAt || "N/A",
    updatedAt: item.updatedAt || "N/A",
    username: item.values?.user?.username || "N/A",
    taskId: item.values?.task || "N/A",
    scheduledAt: item?.values?.scheduledAt || "N/A",
    createdAt: item?.values?.createdAt,
    error: item?.values?.error || "N/A",
    arguments: item?.values?.arguments || "N/A",
  });
  return {
    data: data.logs?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

const flattenSiteActionOverview = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.task?.name || "N/A",
    status: item.status,
    submittedAt: item.createdAt || "N/A",
    scheduledAt: item.scheduledAt || "N/A",
    completedAt: item.completedAt || "N/A",
    updatedAt: item.task?.updatedAt || "N/A",
    username: item.user?.username || "N/A",
    taskId: item.task?.id || "N/A",
    error: item?.error ? item.error.split("\r\n")[0] : "N/A",
    arguments: item?.arguments || "N/A",
  });
  return {
    currentQueue: data.currentQueue?.rows.map(flattenData) || [],
    recentlyCompleted: data.recentlyCompleted?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  tools: [],
  devices: {
    count: 0,
    rows: [],
  },
  selectedTool: {},
  robotsWithCarts: {},
  siteActionOverview: {
    currentQueue: [],
    recentlyCompleted: [],
    latestUpdate: {
      status: "New",
      updatedAt: "",
    },
  },
  siteActionLogs: {
    logs: {
      count: 0,
      rows: [],
      usingTest: false,
    },
    latestUpdate: {
      updatedAt: "",
      status: "New",
    },
  },
  integrationMonitoringLogs: { count: 0, rows: [] },
  unassignedJobs: {
    count: 2,
    rows: [],
  },
  botConfigurations: [], // List of bot configurations
  selectedBotCalculator: {
    siteName: '',
    version: '',
    userInput:{
      metricOrImperial: 'Imperial', // C11
      totalWidthOfRobotArea: 0, // C12
      totalLengthOfRobotArea: 0, // C13
      totalRobotArea: 0, // C14
      numberOfAislesInRobotArea: 0, // C15
      aisleWidth: 0, // C16
      numberOfCutThroughsInRobotArea: 0, // C17
      inductDropoffLocationEntry: '', // C18
      productiveHoursPerDayPicking: 0, // C21
      productiveHoursPerDayPutaway: 0, // C22
      robotOrigin: '', // C23
      determinedPassingCapability: '2-Wide Passing', // C24
      designVolumes: {
          containersPerMission: {
              picking: 0, // C28
              putaway: 0, // D28
          },
          linesPerStop: {
              picking: 0, // C29
              putaway: 0, // D29
          },
          inductLoadTime: {
              picking: 0, // C30
              putaway: 0, // D30
          },
          dropOffUnloadTime: {
              picking: 0, // C31
              putaway: 0, // D31
          },
          ordersPerDay: {
              picking: 0, // C32
              putaway: 0, // D32
          },
          linesPerDay: {
              picking: 0, // C33
              putaway: 0, // D33
          },
          unitsPerDay: {
              picking: 0, // C34
              putaway: 0, // D34
          },
          taskTimePerLine: {
              picking: 0, // C35
              putaway: 0, // D35
          },
      },
    },
    staticInputs: {
      robotClusteringFactor: 0.75, // I9
      pickingAvgJobPercentOfZone: 0.65, // I10
      putawayAvgJobPercentOfZone: 0.40, // I11
      waitTaskTime: 45, // I12 =XLOOKUP("Active",E83:E87,D83:D87,"",0)
      putawayType: "Interleaved", // I13
      pickerPfd: 1, // I14
      batchBot: "FALSE", // I15
      autonomousLoadTime: 0, // I16
      inductToPickArea: 20, // I17
      pickAreaToDropoffAdjustment: 20, // I18
      dropoffQueueTotalTime: 60, // I19
      autonomousUnloadTime: 0, // I20
      travelToInductDistance: 0, // I21
      inductQueueTotalTime: 0, // I22
      averageWalkingSpeed: 3.8, // I23 =IFERROR(IF(C11="Imperial",3.8,1.16),"")
      designFactor: 1, // I24
      inductDropoffFteUtilization: 0.85, // I25
    },    
  botCalculations: {
    robot: {
      picking: "Origin", // C99 =TEXTBEFORE(C23," ",1)
      putaway: "Origin", // D99 =TEXTBEFORE(C23," ",1)
    },
    batchBot: {
      picking: "FALSE", // C100 =I15
      putaway: "FALSE", // D100 =I15
    },
    robotSpeed: {
      picking: 0, // C101 =IF(C11="Imperial",XLOOKUP(C23,B41:B48,C41:C48,"",0),XLOOKUP(C23,B51:B58,C51:C58,"",0))
      putaway: 0, // D101 =IF(C11="Imperial",XLOOKUP(C23,B41:B48,C41:C48,"",0),XLOOKUP(C23,B51:B58,C51:C58,"",0))
    },
    linesPerMission: {
      picking: 0, // C102 =IFERROR(IF(C33/C32)*C28,"")
      putaway: 0, // D102 =IFERROR(IF(D33/D32)*D28,"")
    },
    linesPerStop: {
      picking: 0, // C103 =C29
      putaway: 0, // D103 =D29
    },
    inductTotalTime: {
      picking: 0, // C104 =C30
      putaway: 0, // D104 =D30
    },
    autonomousLoadTime: {
      picking: 0, // C105 =I16
      putaway: 0, // D105 =I16
    },
    inductDropoffLocationEntry: {
      picking: "", // C106 =C18
      putaway: "", // D106 =C18
    },
    inductToPickArea: {
      picking: 0, // C107 =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
      putaway: 0, // D107 =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
    },
    travelToFirstPickDistance: {
      picking: 0, // C108 =IFERROR(XLOOKUP(C106,B77:B80,C77:C80,"",0)*C107,"")
      putaway: 0, // D108 =IFERROR(XLOOKUP(D106,B77:B80,C77:C80,"",0)*D107,"")
    },
    travelToFirstPickTime: {
      picking: 0, // C109 =IFERROR(C108/C101,"")
      putaway: 0, // D109 =IFERROR(D108/D101,"")
    },
    waitForFteTime: {
      picking: 0, // C110 =I12
      putaway: 0, // D110 =I12
    },
    waitForFteQty: {
      picking: 0, // C111 =C102
      putaway: 0, // D111 =D102
    },
    waitForFteTotalTime: {
      picking: 0, // C112 =IFERROR(C110*C111,"")
      putaway: 0, // D112 =IFERROR(D110*D111,"")
    },
    pickLineTime: {
      picking: 0, // C113 =C35
      putaway: 0, // D113 =D35
    },
    pickLineQty: {
      picking: 0, // C114 =C102
      putaway: 0, // D114 =D102
    },
    pickLineTotalTime: {
      picking: 0, // C115 =IFERROR(C113*C114,"")
      putaway: 0, // D115 =IFERROR(D113*D114,"")
    },
    avgTasksPerAisle: {
      picking: 0, // C116 =IFERROR(C102/C103/I10*C15,"")
      putaway: 0, // D116 =IFERROR(D102/D103/I11*C15,"")
    },
    avgJobPercentageOfZone: {
      picking: 0, // C117 =I10
      putaway: 0, // D117 =I11
    },
    navigationFactor: {
      picking: 0, // C118 =XLOOKUP(C24,B61:B64,C61:C64,"",0)
      putaway: 0, // D118 =XLOOKUP(C24,B61:B64,C61:C64,"",0)
    },
    travelToNextPickDistance: {
      picking: 0, // C119 =IFERROR(MIN(XLOOKUP(C17,B67:B73,C67:C73,"",0)*C13,C13/C116)*C13+C12*C117*(1/C114)*C118,"")
      putaway: 0, // D119 =IFERROR(MIN(XLOOKUP(C17,B67:B73,C67:C73,"",0)*C13,C13/D116)*C13+C12*D117*(1/D114)*D118,"")
    },
    travelToNextPickTime: {
      picking: 0, // C120 =IFERROR(C119/C101,"")
      putaway: 0, // D120 =IFERROR(D119/D101,"")
    },
    travelToNextPickQty: {
      picking: 0, // C121 =IFERROR((C102/C103)-1,"")
      putaway: 0, // D121 =IFERROR((D102/D103)-1,"")
    },
    travelToNextPickTotalTime: {
      picking: 0, // C122 =IFERROR(C121*C120,"")
      putaway: 0, // D122 =IFERROR(D121*D120,"")
    },
    pickAreaToDropoffAdjustment: {
      picking: 0, // C123 =IFERROR(IF(C11="Imperial",I18,CONVERT(I18,"ft","m")),"")
      putaway: 0, // D123 =IFERROR(IF(C11="Imperial",I18,CONVERT(I18,"ft","m")),"")
    },
    travelToDropoffDistance: {
      picking: 0, // C124 =IFERROR(XLOOKUP(C106,B77:B80,C77:C80,"",0)*C123,"")
      putaway: 0, // D124 =IFERROR(XLOOKUP(D106,B77:B80,C77:C80,"",0)*D123,"")
    },
    travelToDropoffTotalTime: {
      picking: 0, // C125 =C124/C101
      putaway: 0, // D125 =D124/D101
    },
    dropoffQueueTotalTime: {
      picking: 0, // C126 =I19
      putaway: 0, // D126 =I19
    },
    dropoffTotalTime: {
      picking: 0, // C127 =C31
      putaway: 0, // D127 =D31
    },
    autonomousUnloadTime: {
      picking: 0, // C128 =I20
      putaway: 0, // D128 =I20
    },
    travelToInductDistance: {
      picking: 0, // C129 =IFERROR(IF(C11="Imperial",I21,CONVERT(I21,"ft","m")),"")
      putaway: 0, // D129 =IFERROR(IF(C11="Imperial",I21,CONVERT(I21,"ft","m")),"")
    },
    travelToInductTotalTime: {
      picking: 0, // C130 =IFERROR(C129/C101,"")
      putaway: 0, // D130 =IFERROR(D129/D101,"")
    },
    inductQueueTotalTime: {
      picking: 0, // C131 =IFERROR(IF(C130>0,I22,0),"")
      putaway: 0, // D131 =IFERROR(IF(D130>0,I22,0),"")
    },
    activePickTime: {
      picking: 0, // C132 =IFERROR(C112-C110+C115+C122,"")
      putaway: 0, // D132 =IFERROR(D112-D110+D115+D122,"")
    },
    averageMissionTimeSec: {
      picking: 0, // C133 =IFERROR(IF(C105>0,C105,C104)+C109+C112+C115+C122+C125+C126+IF(C128>0,C128,C127)+C130+C131,"")
      putaway: 0, // D133 =IFERROR(IF(D105>0,D105,D104)+D109+D112+D115+D122+D125+D126+IF(D128>0,D128,D127)+D130+D131,"")
    },
    averageActivePickingPercentage: {
      picking: 0, // C134 =IFERROR(C132/C133,"")
      putaway: 0, // D134 =IFERROR(D132/D133,"")
    },
    totalJobsPerHourRequired: {
      picking: 0, // C135 =IFERROR((C33/C102)/C21,"")
      putaway: 0, // D135 =IFERROR((D33/D102)/C22,"")
    },
    averageRobotMissionDistance: {
      picking: 0, // C136 =IFERROR(C108+(C119*C121)+C124+C129,"")
      putaway: 0, // D136 =IFERROR(D108+(D119*D121)+D124+D129,"")
    },
    averageMissionTimeMin: {
      picking: 0, // C137 =IFERROR(C133/60,"")
      putaway: 0, // D137 =IFERROR(D133/60,"")
    },
    robotJobsPerHour: {
      picking: 0, // C138 =IFERROR(60/C137,"")
      putaway: 0, // D138 =IFERROR(60/D137,"")
    },
    averageAvailableTime: {
      picking: 0, // C139 =IFERROR((C109+IF(C100=FALSE,C112+C122,C110))/C133,"")
      putaway: 0, // D139 =IFERROR((D109+IF(D100=FALSE,D112+D122,D110))/D133,"")
    },
    activeRobots: {
      picking: 0, // C140 =IFERROR(C135/C138,"")
      putaway: 0, // D140 =IFERROR(D135/D138,"")
    },
    chargingBuffer: {
      picking: 0, // C141 =IFERROR(XLOOKUP(C23,B41:B48,D41:D48,"",0),"")
      putaway: 0, // D141 =IFERROR(XLOOKUP(C23,B41:B48,D41:D48,"",0),"")
    },
    chargingRobots: {
      picking: 0, // C142 =C141*C140
      putaway: 0, // D142 =D141*D140
    },
    totalRobots: {
      picking: 0, // C143 =C140+C142
      putaway: 0, // D143 =D140+D142
    },
    availableRobots: {
      picking: 0, // C144 =C140*C139
      putaway: 0, // D144 =D140*D139
    },
    activeRobotLphDesignVol: {
      picking: 0, // C145 =IFERROR(C102/(C133/3600),"")
      putaway: 0, // D145 =IFERROR(D102/(D133/3600),"")
    },
    activeRobotUphDesignVol: {
      picking: 0, // C146 =IFERROR(C145*C157,"")
      putaway: 0, // D146 =IFERROR(D145*D157,"")
    },
    designLinesPerHour: {
      picking: 0, // C147 =IFERROR(C33/C21,"")
      putaway: 0, // D147 =IFERROR(D33/C22,"")
    },
    allRobotLphDesignVol: {
      picking: 0, // C148 =IFERROR(C147/C143,"")
      putaway: 0, // D148 =IFERROR(D147/D143,"")
    },
    allRobotUphDesignVol: {
      picking: 0, // C149 =IFERROR(C148*C157,"")
      putaway: 0, // D149 =IFERROR(D148*D157,"")
    },
    walkToNewRobotDistance: {
      picking: 0, // C150 =IF(C143="",C95,E95)
      putaway: 0, // D150 =IF(D143="",D95,E95)
    },
    walkToNewBotSeconds: {
      picking: 0, // C151 =IFERROR(C150/I23,"")
      putaway: 0, // D151 =IFERROR(D150/I23,"")
    },
    repeatedAction: {
      picking: "Location", // C152 =IFERROR(IF(C100=TRUE,"Task List",IF(C100=FALSE,"Location")),"")
      putaway: "Location", // D152 =IFERROR(IF(D100=TRUE,"Task List",IF(D100=FALSE,"Location")),"")
    },
    repeatedActionSeconds: {
      picking: 0, // C153 =IFERROR(IF(C152="Location",C113*C103,C132),"")
      putaway: 0, // D153 =IFERROR(IF(D152="Location",D113*D103,D132),"")
    },
    linesPerCycle: {
      picking: 0, // C154 =IFERROR(IF(C152="Location",C103,C102),"")
      putaway: 0, // D154 =IFERROR(IF(D152="Location",D103,D102),"")
    },
    timePerCycleSeconds: {
      picking: 0, // C155 =IFERROR(C153+C151,"")
      putaway: 0, // D155 =IFERROR(D153+D151,"")
    },
    lph: {
      picking: 0, // C156 =IFERROR((3600/(C155/C154))*I14,"")
      putaway: 0, // D156 =IFERROR((3600/(D155/D154))*I14,"")
    },
    unitsPerLine: {
      picking: 0, // C157 =IFERROR(C34/C33,"")
      putaway: 0, // D157 =IFERROR(D34/D33,"")
    },
    uph: {
      picking: 0, // C158 =IFERROR(C156*C157,"")
      putaway: 0, // D158 =IFERROR(D156*D157,"")
    },
    designVolumeUnitsPerHour: {
      picking: 0, // C159 =IFERROR(C34/C21,"")
      putaway: 0, // D159 =IFERROR(D34/C22,"")
    },
    totalDesignFtes: {
      picking: 0, // C160 =IFERROR(C162+C163,"")
      putaway: 0, // D160 =IFERROR(D162+D163,"")
    },
    designVolumeWithFactorUnitsPerHour: {
      picking: 0, // C161 =IFERROR(C159*I24,"")
      putaway: 0, // D161 =IFERROR(D159*I24,"")
    },
    designDirectFtes: {
      picking: 0, // C162 =IFERROR(C161/C158,"")
      putaway: 0, // D162 =IFERROR(D161/D158,"")
    },
    designIndirectFtes: {
      picking: 0, // C163 =IFERROR(C164+C165,"")
      putaway: 0, // D163 =IFERROR(D164+D165,"")
    },
    designInductOperators: {
      picking: 0, // C164 =IFERROR((C135*C104/60)/125/60*I25,"")
      putaway: 0, // D164 =IFERROR((D135*D104/60)/125/60*I25,"")
    },
    designDropOffOperators: {
      picking: 0, // C165 =IFERROR((C135*C127/60)/125/60*I25,"")
      putaway: 0, // D165 =IFERROR((D135*D127/60)/125/60*I25,"")
    }
    // ... continue with remaining calculations ...
  },  
    lookupReference: {
        robotSpecs: {
          imperial: {
            origin20_5: {
              avgSpeed: 3, // C41
              buffer: 0.1, // D41
              noPass: 3.5, // E41
              twoWide: 5.33, // F41
              threeWide: 8, // G41
            },
            origin22_5: {
              avgSpeed: 3, // C42
              buffer: 0.1, // D42
              noPass: 3.5, // E42
              twoWide: 5.58, // F42
              threeWide: 8.42, // G42
            },
            origin24_5: {
              avgSpeed: 3, // C43
              buffer: 0.1, // D43
              noPass: 3.5, // E43
              twoWide: 5.92, // F43
              threeWide: 8.83, // G43
            },
            origin26_5: {
              avgSpeed: 3, // C44
              buffer: 0.1, // D44
              noPass: 3.5, // E44
              twoWide: 6.17, // F44
              threeWide: 9.25, // G44
            },
            vectorFixedShelf: {
              avgSpeed: 2.5, // C45
              buffer: 0.15, // D45
              noPass: 4.08, // E45
              twoWide: 8.17, // F45
              threeWide: 12.33, // G45
            },
            vectorLift_2x4: {
              avgSpeed: 2.5, // C46
              buffer: 0.15, // D46
              noPass: 4.33, // E46
              twoWide: 8.5, // F46
              threeWide: 12.67, // G46
            },
            vectorLift_2x5: {
              avgSpeed: 2.5, // C47
              buffer: 0.15, // D47
              noPass: 4.33, // E47
              twoWide: 8.67, // F47
              threeWide: 13.08, // G47
            },
            vectorLift_2x6: {
              avgSpeed: 2.5, // C48
              buffer: 0.15, // D48
              noPass: 4.42, // E48
              twoWide: 8.67, // F48
              threeWide: 13, // G48
            },
          },
          metric: {
            origin20_5: {
              avgSpeed: 0.914, // C51
              buffer: 0.1, // D51
              noPass: 1.07, // E51
              twoWide: 1.63, // F51
              threeWide: 2.44, // G51
            },
            origin22_5: {
              avgSpeed: 0.914, // C52
              buffer: 0.1, // D52
              noPass: 1.07, // E52
              twoWide: 1.7, // F52
              threeWide: 2.57, // G52
            },
            origin24_5: {
              avgSpeed: 0.914, // C53
              buffer: 0.1, // D53
              noPass: 1.07, // E53
              twoWide: 1.8, // F53
              threeWide: 2.69, // G53
            },
            origin26_5: {
              avgSpeed: 0.914, // C54
              buffer: 0.1, // D54
              noPass: 1.07, // E54
              twoWide: 1.88, // F54
              threeWide: 2.82, // G54
            },
            vectorFixedShelf: {
              avgSpeed: 0.762, // C55
              buffer: 0.15, // D55
              noPass: 1.24, // E55
              twoWide: 2.49, // F55
              threeWide: 3.76, // G55
            },
            vectorLift_2x4: {
              avgSpeed: 0.762, // C56
              buffer: 0.15, // D56
              noPass: 1.32, // E56
              twoWide: 2.59, // F56
              threeWide: 3.86, // G56
            },
            vectorLift_2x5: {
              avgSpeed: 0.762, // C57
              buffer: 0.15, // D57
              noPass: 1.32, // E57
              twoWide: 2.64, // F57
              threeWide: 3.99, // G57
            },
            vectorLift_2x6: {
              avgSpeed: 0.762, // C58
              buffer: 0.15, // D58
              noPass: 1.35, // E58
              twoWide: 2.64, // F58
              threeWide: 3.96, // G58
            },
          },
        },
        navFactor: {
          unableToEnterAisle: 1.5, // C61
          noPass: 1.3, // D61
          twoWidePassing: 1.05, // E61
          threeWidePassing: 1.025, // F61
        },
        cutThroughEffectiveness: [
          { 
            cutThroughs: 0, // B67
            percentOfAisle: 0.50, // C67 =C13/(B67+1)/2
            endCap: 400.00, // D67 =C12*0.5
          },
          { 
            cutThroughs: 1, // B68
            percentOfAisle: 0.45, // C68 =C13/(B68+1)/2
            endCap: 287.50, // D68 =C12*0.45
          },
          { 
            cutThroughs: 2, // B69
            percentOfAisle: 0.40, // C69 =C13/(B69+1)/2
            endCap: 300.00, // D69 =C12*0.4
          },
          { 
            cutThroughs: 3, // B70
            percentOfAisle: 0.38, // C70 =(C13+0.25)/(B70+1)
            endCap: 287.50, // D70 =C12*0.38
          },
          { 
            cutThroughs: 4, // B71
            percentOfAisle: 0.37, // C71 =C13/(B71+1)
            endCap: 292.00, // D71 =(C13*0.37)*C12
          },
          { 
            cutThroughs: 5, // B72
            percentOfAisle: 0.36, // C72 =C13/(B72+1)
            endCap: 287.50, // D72 =C12*0.36
          },
          { 
            cutThroughs: 6, // B73
            percentOfAisle: 0.35, // C73 =C13/(B73+1)
            endCap: 289.74, // D73 =AVERAGE(C13,D73)
          },
          { 
            cutThroughs: 2, // B74
            percentOfAisle: null, // C74
            endCap: 300.00, // D74 (no calculated field in this row)
          },
        ],    
        distRef: {
          cornerOfPickSpace: 325, // C77
          centerOfSpaceAlongEndCaps: 200, // C78 =0.5*C12+(0.5*C13)
          centerOfSpaceAlongAislePickFaces: 300, // C79 =0.5*C13+(0.5*C12)
          centerOfPickSpace: 162.5, // C80 =0.25*C13+(0.25*C12)
        },
        pickDensity: [
          { 
            lowerBounds: null, // B83
            upperBounds: 1, // C83
            dwellTime: 120, // D83
          },
          { 
            lowerBounds: 1, // B84
            upperBounds: 3, // C84
            dwellTime: 90, // D84
          },
          { 
            lowerBounds: 3, // B85
            upperBounds: 10, // C85
            dwellTime: 75, // D85
          },
          { 
            lowerBounds: 10, // B86
            upperBounds: 20, // C86
            dwellTime: 60, // D86
          },
          { 
            lowerBounds: 20, // B87
            upperBounds: null, // C87
            dwellTime: 45, // D87
          },
        ],  
        walkDistance: {
          availableRobots: { 
            picking: 59.02, // C90 =C144/D90
            putaway: 6.403, // D90 =C144/D91
            concurrent: 65.419, // E90 =C144/D92
          },
          areaPerAvailableBot: { 
            picking: 1270.8, // C91 =IFERROR(C14/C90,"")
            putaway: 11712.6, // D91 =IFERROR(C14/C91,"")
            concurrent: 1146.4, // E91 =IFERROR(C14/C92,"")
          },
          xPerRobot: { 
            picking: 65.1, // C92 =IFERROR(SQRT(C91/(SQRT(C12/C19))),"")
            putaway: 197.6, // D92 =IFERROR(SQRT(D91/(SQRT(C12/C13))),"")
            concurrent: 61.8, // E92 =IFERROR(SQRT(E91/(SQRT(C12/C13))),"")
          },
          yPerRobot: { 
            picking: 19.5, // C93 =IFERROR(SQRT(C91/(SQRT(C12/C19))),"")
            putaway: 59.3, // D93 =IFERROR(SQRT(D91/(SQRT(C12/C13))),"")
            concurrent: 18.5, // E93 =IFERROR(SQRT(E91/(SQRT(C12/C13))),"")
          },
          walkDistanceWithoutCluster: { 
            picking: 76.3, // C94 =AVERAGE(C92+D93,SQRT(C92^2+C93^2))
            putaway: 231.6, // D94 =AVERAGE(D92+D93,SQRT(D92^2+D93^2))
            concurrent: 72.5, // E94 =AVERAGE(E92+E93,SQRT(E92^2+E93^2))
          },
          walkDistanceWithCluster: { 
            picking: 57.2, // C95 =C94*0.9
            putaway: 173.7, // D95 =D94*0.9
            concurrent: 54.3, // E95 =E94*0.9
          },
        },
        
        
    },
    outputs: {
      pickingOnlySummary: {
        ops: {
          productiveHoursPerDay: "0.0 hrs", // O9 =C32
        },
        designDemandVolume: {
          ordersPerDay: "0", // O12 =C32
          linesPerDay: "0", // O13 =C34
          unitsPerDay: "0", // O14 =C33
          ordersPerHour: "0", // O15 =C32/C21
          linesPerHour: "0", // O16 =C33/C21
          unitsPerHour: "0" // O17 =C34/C21
        },
        botMetrics: {
          numberOfRobots: "0", // O19 =ROUNDUP(C143,-0.1)
          robotLph: "0.00", // O20 =C148
          robotUph: "0.00", // O21 =C149
          avgRobotMissionMin: "00:00" // O22 =C137/1440
        },
        pickingMetrics: {
          numberOfPickers: "0.0", // O24 =C162
          inductDropoffFtes: "0.0", // O25 =C163
          totalFtes: "0.0", // O26 =C160
          purePickLph: "0", // O27 =C156
          purePickUph: "0", // O28 =C158
          totalFteLph: "0", // O29 =(C161/C160)/C157
          totalFteUph: "0" // O30 =C161/C160
        },
        systemMetrics: {
          robotToPickerRatio: "0:1", // O32 =ROUND(C143/C162,1)&":"&1
          robotToFteRatio: "0:1", // O33 =ROUND(C143/C160,1)&":"&1
          pickArea: "0", // O34 =C14
          areaPerBot: "0", // O35 =C14/ROUNDUP(C143,0)
          areaPerPicker: "0" // O36 =C14/C162
        }
      },
      putawayOnlySummary: {
        ops: {
          productiveHoursPerDay: "0.0 hrs" // T9 =C22
        },
        designDemandVolume: {
          containersPerDay: "0", // T12 =D32
          linesPerDay: "0", // T13 =D33
          unitsPerDay: "0", // T14 =D34
          containersPerHour: "0", // T15 =D32/C22
          linesPerHour: "0", // T16 =D33/C22
          unitsPerHour: "0" // T17 =D34/C22
        },
        botMetrics: {
          numberOfRobots: "0", // T19 =ROUNDUP(D143,-0.1)
          robotLph: "0.00", // T20 =D148
          robotUph: "0.00", // T21 =D149
          avgRobotMissionMin: "00:00" // T22 =D137/1440
        },
        putawayMetrics: {
          numberOfPickers: "0.0", // T24 =D162
          inductDropoffFtes: "0.0", // T25 =D163
          totalFtes: "0.0", // T26 =D160
          purePutLph: "0", // T27 =D156
          purePutUph: "0", // T28 =D158
          totalFteLph: "0", // T29 =(D161/D160)/D157
          totalFteUph: "0" // T30 =D161/D160
        },
        systemMetrics: {
          robotToPickerRatio: "0:1", // T32 =ROUND(D143/D162,1)&":"&1
          robotToFteRatio: "0:1", // T33 =ROUND(D143/D160,1)&":"&1
          pickArea: "0", // T34 =C14
          areaPerBot: "0", // T35 =C14/ROUNDUP(D143,0)
          areaPerPicker: "0" // T36 =C14/D162
        }
      },      
      concurrentSummary: {
        ops: {
          productivePickingHoursPerDay: "0.0 hrs", // Y9 =C21
          productivePutawayHoursPerDay: "0.0 hrs" // Y10 =C22
        },
        designDemandVolume: {
          pickingOrdersPerHour: "0", // Y12 =C32/C21
          pickingLinesPerHour: "0", // Y13 =C33/C21
          pickingUnitsPerHour: "0", // Y14 =C34/C21
          putawayContainersPerHour: "0", // Y15 =D32/C22
          putawayLinesPerHour: "0", // Y16 =D33/C22
          putawayUnitsPerHour: "0" // Y17 =D33/C22
        },
        pickingBotMetrics: {
          numberOfPickingBots: "0.0", // Y19 =C143
          robotPickingLph: "0", // Y20 =C148
          robotPickingUph: "0", // Y21 =C149
          avgRobotMissionMin: "00:00" // Y22 =C137/1440
        },
        putawayBotMetrics: {
          numberOfPutawayBots: "0.0", // Y24 =D143
          robotPutawayLph: "0", // Y25 =D148
          robotPutawayUph: "0", // Y26 =D149
          avgRobotMissionMin: "00:00" // Y27 =D137/1440
        },
        total: {
          totalNumberOfRobots: "0" // Y29 =ROUNDUP(C143+D143,0)
        },
        staffing: {
          numberOfDirectFtes: "0.0", // Y31 =SUM(C162:D162)
          numberOfIndirectPickingFtes: "0.0", // Y32 =C163
          numberOfIndirectPutawayFtes: "0.0", // Y33 =D163
          totalFtes: "0.0" // Y34 =SUM(Y31:Y33)
        },
        pickingMetrics: {
          purePickLph: "0", // Y36 =C156
          purePickUph: "0", // Y37 =C158
          totalFteLph: "0", // Y38 =(C161/C160)/C157
          totalFteUph: "0" // Y39 =C161/C160
        },
        putawayMetrics: {
          purePutLph: "0", // Y41 =D156
          purePutUph: "0", // Y42 =D158
          totalFteLph: "0", // Y43 =(D161/D160)/D157
          totalFteUph: "0" // Y44 =D161/D160
        },
        systemMetrics: {
          robotToPickerRatio: "0:1", // Y46 =ROUND((C143+D143)/(C162+D162),1)&":"&1
          robotToFteRatio: "0:1", // Y47 =ROUND((C143+D143)/(C160+D160),1)&":"&1
          pickArea: "0", // Y48 =C14
          areaPerBot: "0", // Y49 =C14/ROUNDUP(C143+D143,0)
          areaPerPicker: "0" // Y50 =C14/(C162+D162)
        }
      },      
      pickingBotMissionWorkflow: {
        workflow: {
          inductTote: { 
            distance: null, // J40
            time: 0, // M40 =C104
            multiplier: 1, // O40
            totalTime: 0 // Q40 =M40*O40
          },
          travelToFirstPick: { 
            distance: 0, // L41 =C108
            time: 0, // M41 =C109
            multiplier: 1, // O41
            totalTime: 0 // Q41 =M41*O41
          },
          waitForPicker: { 
            distance: null, // J42
            time: 0, // M42 =C110
            multiplier: 1, // O42
            totalTime: 0 // Q42 =M42*O42
          },
          pickLineItems: { 
            distance: null, // J43
            time: 0, // M43 =C113
            multiplier: 1, // O43
            totalTime: 0 // Q43 =M43*O43
          },
          travelToNextPick: { 
            distance: 0, // L44 =C119
            time: 0, // M44 =C120
            multiplier: 0, // O44 =C121
            totalTime: 0 // Q44 =M44*O44
          },
          secondWaitForPicker: { 
            distance: null, // J45
            time: 0, // M45 =C110
            multiplier: 0, // O45
            totalTime: 0 // Q45 =M45*O45
          },
          secondPickLineItems: { 
            distance: null, // J46
            time: 0, // M46 =C113
            multiplier: 0, // O46
            totalTime: 0 // Q46 =M46*O46
          },
          travelToDropoff: { 
            distance: 0, // L47 =C124
            time: 0, // M47 =C125
            multiplier: 1, // O47
            totalTime: 0 // Q47 =M47*O47
          },
          dropoffQueue: { 
            distance: null, // J48
            time: 0, // M48 =C126
            multiplier: 1, // O48
            totalTime: 0 // Q48 =M48*O48
          },
          removeTote: { 
            distance: null, // J49
            time: 0, // M49 =C127
            multiplier: 1, // O49
            totalTime: 0 // Q49 =M49*O49
          }
        },
        summaryMetrics: {
          avgActivePickingTime: 0, // L51 =SUM(Q43:Q46)/SUM(Q40:Q49)
          totalJobsPerHourRequired: 0, // L52 =C135
          avgRobotMissionDistance: 0, // L53 =C136
          avgJobTimeMin: 0, // Q51 =SUM(Q40:Q49)/60
          robotJobsPerHour: 0, // Q52 =60/Q51
          avgAvailableTime: 0 // Q53 =C139
        }
      },    
      putawayBotMissionWorkflow: {
        workflow: {
          inductTote: { 
            distance: null, // J57
            time: 0, // M57 =D104
            multiplier: 1, // O57
            totalTime: 0 // Q57 =M57*O57
          },
          travelToFirstPick: { 
            distance: 0, // L58 =C125
            time: 0, // M58 =D109
            multiplier: 1, // O58
            totalTime: 0 // Q58 =M58*O58
          },
          waitForPicker: { 
            distance: null, // J59
            time: 0, // M59 =D110
            multiplier: 1, // O59
            totalTime: 0 // Q59 =M59*O59
          },
          pickLineItems: { 
            distance: null, // J60
            time: 0, // M60 =D113
            multiplier: 1, // O60
            totalTime: 0 // Q60 =M60*O60
          },
          travelToNextPick: { 
            distance: 0, // L61 =D119
            time: 0, // M61 =D120
            multiplier: 0, // O61 =D121
            totalTime: 0 // Q61 =M61*O61
          },
          secondWaitForPicker: { 
            distance: null, // J62
            time: 0, // M62 =D110
            multiplier: 0, // O62 =D121
            totalTime: 0 // Q62 =M62*O62
          },
          secondPickLineItems: { 
            distance: null, // J63
            time: 0, // M63 =D113
            multiplier: 0, // O63 =D121
            totalTime: 0 // Q63 =M63*O63
          },
          travelToDropoff: { 
            distance: 0, // L64 =D124
            time: 0, // M64 =D125
            multiplier: 1, // O64
            totalTime: 0 // Q64 =M64*O64
          },
          dropoffQueue: { 
            distance: null, // J65
            time: 0, // M65 =D126
            multiplier: 1, // O65
            totalTime: 0 // Q65 =M65*O65
          },
          removeTote: { 
            distance: null, // J66
            time: 0, // M66 =D127
            multiplier: 1, // O66
            totalTime: 0 // Q66 =M66*O66
          }
        },
        summaryMetrics: {
          avgActivePickingTime: "0.0%", // M68 =SUM(Q60:Q63)/SUM(Q57:Q66)
          totalJobsPerHourRequired: "0", // M69 =D135
          avgRobotMissionDistance: "0", // M70 =D136
          avgJobTimeMin: "0", // Q68 =SUM(Q57:Q66)/60
          robotJobsPerHour: "0", // Q69 =60/Q68
          avgAvailableTime: "0.0%" // Q70 =D139
        }
      }
    },
}

};

const slice = createSlice({
  name: "tool",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getToolsSuccess(state, action) {
      state.tools = action.payload;
    },
    getSelectedToolsSuccess(state, action) {
      state.selectedTool = action.payload;
    },
    getRobotsWithCartsSuccess(state, action) {
      state.robotsWithCarts = action.payload;
    },
    getSiteActionOverviewSuccess(state, action) {
      state.siteActionOverview = action.payload;
    },
    updateStatusSuccess(state, action) {
      state.siteActionOverview.latestUpdate.status = action.payload;
    },
    getSiteActionLogsSuccess(state, action) {
      state.siteActionLogs = action.payload;
    },
    getMerakiDevicesSuccess(state, action) {
      state.devices = action.payload;
    },
    getIntegrationMonitoringLogsSuccess(state, action) {
      state.integrationMonitoringLogs = action.payload;
    },
    getUnassignedJobsSuccess(state, action) {
      state.unassignedJobs = action.payload;
    },
    getBotConfigurationsSuccess(state, action) {
      state.botConfigurations = action.payload;
    },
    getBotCalculatorSuccess(state, action) {
      state.selectedBotCalculator.userInput = action.payload;
    },
    updateBotCalculator: (state, action) => {
      console.log('Updating bot calculator with payload:', action.payload); 
      
      // Update siteName if provided
      if (action.payload.siteName !== undefined) {
        state.selectedBotCalculator.siteName = action.payload.siteName;
      }
      
      // Update user input
      if (action.payload.userInput) {
        // Handle nested userInput structure
        const userInput = action.payload.userInput.userInput || action.payload.userInput;
        
        // Merge with existing userInput, preserving all values
        state.selectedBotCalculator.userInput = {
          ...state.selectedBotCalculator.userInput,
          ...userInput,
          designVolumes: {
            ...state.selectedBotCalculator.userInput.designVolumes,
            ...userInput.designVolumes
          }
        };

        console.log('Updated user input:', state.selectedBotCalculator.userInput);
      }

      // First, update lookup references since bot calculations depend on them
      const lookupParams = {
        userInput: state.selectedBotCalculator.userInput,
        staticInputs: state.selectedBotCalculator.staticInputs
      };

      // Update all computed lookups
      const lookupMetadata = getLookupMetadata();
      const computedLookups = {};
      
      Object.entries(lookupMetadata)
        .filter(([_, meta]) => meta.type === 'computed')
        .forEach(([key, _]) => {
          computedLookups[key] = getLookupValue(key, lookupParams);
        });

      // Store computed lookup results
      state.selectedBotCalculator.lookupReference = {
        ...state.selectedBotCalculator.lookupReference,
        ...computedLookups
      };

      // Now that lookups are updated, calculate bot metrics
      const calculationResults = calculateBotMetrics(
        state.selectedBotCalculator.userInput,
        state.selectedBotCalculator.staticInputs,
        state.selectedBotCalculator.lookupReference
      );

      // Update calculated values while preserving structure
      state.selectedBotCalculator.botCalculations = {
        ...state.selectedBotCalculator.botCalculations,
        ...calculationResults.values
      };

      // Store calculation metadata
      state.selectedBotCalculator.calculationMetadata = calculationResults.metadata;

      // Finally, calculate outputs using updated lookups and bot calculations
      const outputResults = calculateOutputs(
        state.selectedBotCalculator.userInput,
        state.selectedBotCalculator.staticInputs,
        state.selectedBotCalculator.lookupReference,
        state.selectedBotCalculator.botCalculations
      );

      // Update outputs while preserving structure
      state.selectedBotCalculator.outputs = {
        ...state.selectedBotCalculator.outputs,
        ...outputResults.values
      };

      // Store output metadata
      state.selectedBotCalculator.outputMetadata = outputResults.metadata;

      console.log('Updated Bot Calculations:', state.selectedBotCalculator.botCalculations);
      console.log('Updated Outputs:', outputResults);
    },
    updateSelectedDesignVolume: (state, action) => {
      console.log('Updating design volume with payload:', action.payload);
      const { key, picking, putaway } = action.payload;
      
      // Ensure the path exists
      if (!state.selectedBotCalculator?.userInput?.designVolumes) {
        console.log('Initializing designVolumes object');
        state.selectedBotCalculator = {
          ...state.selectedBotCalculator,
          userInput: {
            ...state.selectedBotCalculator?.userInput,
            designVolumes: {}
          }
        };
      }

      const currentDesignVolumes = state.selectedBotCalculator.userInput.designVolumes;
      console.log('Current designVolumes:', currentDesignVolumes);
      
      // Ensure key is a string
      const stringKey = String(key?.key);
      
      // Create new designVolumes object with all existing values
      const updatedDesignVolumes = {
        ...currentDesignVolumes,
        [stringKey]: {
          picking: key.picking !== undefined ? key.picking : currentDesignVolumes[stringKey]?.picking ?? 0,
          putaway: key.putaway !== undefined ? key.putaway : currentDesignVolumes[stringKey]?.putaway ?? 0
        }
      };
      
      // Update the entire designVolumes object
      state.selectedBotCalculator.userInput.designVolumes = updatedDesignVolumes;
      
      console.log('Updated designVolumes:', state.selectedBotCalculator.userInput.designVolumes);

      // Trigger recalculations
      const calculationResults = calculateBotMetrics(
        state.selectedBotCalculator.userInput,
        state.selectedBotCalculator.staticInputs,
        state.selectedBotCalculator.lookupReference
      );

      // Update bot calculations
      state.selectedBotCalculator.botCalculations = {
        ...state.selectedBotCalculator.botCalculations,
        ...calculationResults.values
      };

      // Update outputs
      const outputResults = calculateOutputs(
        state.selectedBotCalculator.userInput,
        state.selectedBotCalculator.staticInputs,
        state.selectedBotCalculator.lookupReference,
        state.selectedBotCalculator.botCalculations
      );

      state.selectedBotCalculator.outputs = {
        ...state.selectedBotCalculator.outputs,
        ...outputResults.values
      };
    },
    updateSelectedBotCalculator: (state, action) => {
      console.log('updateSelectedBotCalculator called with:', action.payload);
      
      // Update userInput
      if (action.payload.userInput) {
        state.selectedBotCalculator.userInput = {
          ...state.selectedBotCalculator.userInput,
          ...action.payload.userInput
        };
      }

      // Get current state values
      const userInput = state.selectedBotCalculator.userInput;
      const staticInputs = state.selectedBotCalculator.staticInputs;
      const lookupReference = state.selectedBotCalculator.lookupReference;

      console.log('Calculating bot metrics with:', {
        userInput,
        staticInputs,
        lookupReference
      });

      // Calculate new values
      const calculationResults = calculateBotMetrics(userInput, staticInputs, lookupReference);
      console.log('Bot metric calculation results:', calculationResults);

      // Update bot calculations
      state.selectedBotCalculator.botCalculations = calculationResults.values;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// Actions

export function getTools() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/tools`,
        {},
        {}
      );
      dispatch(slice.actions.getToolsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBotConfigurations(warehouse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation`,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        }
      );
      dispatch(slice.actions.getBotConfigurationsSuccess(response.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBotCalculationById(id, warehouse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation/${id}`,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        }
      );
      
      // Create the payload in the format expected by updateBotCalculator
      const payload = {
        siteName: response.data.siteName,
        version: response.data.version,
        userInput: response.data.userInput,
        staticInputs: response.data.staticInput // Convert from staticInput to staticInputs
      };

      // Dispatch updateBotCalculator with the formatted payload
      dispatch(slice.actions.updateBotCalculator(payload));
      
      return response.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createBotCalculation(calculationData, warehouse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation`,
        calculationData,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        }
      );
      return response.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateBotCalculation(id, calculationData, warehouse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation/${id}`,
        calculationData,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        }
      );
      return response.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getRobotsWithCarts({ site, searchFilter }) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/cart/robot`,
        {
          headers: {
            "locus-warehouse": site.condensedName,
          },
          params: {
            ...searchFilter,
          },
        }
      );

      const processedRows = [];
      response.data?.rows?.forEach((robotItem, rIdx) => {
        robotItem?.carts?.forEach((cartItem, cIdx) => {
          processedRows.push({ id: `${rIdx}-${cIdx}`, cart: cartItem, robot: robotItem.robot });
        });
      });

      dispatch(
        slice.actions.getRobotsWithCartsSuccess({
          count: response?.data?.count,
          rows: processedRows,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSiteActionLogs(
  site,
  environment,
  timestampRange,
  task = undefined,
  page,
  pageSize
) {
  return async () => {
    const timestampRangeString =
      timestampRange[0] && timestampRange[1]
        ? `${new Date(timestampRange[0]).toISOString()},${new Date(timestampRange[1]).toISOString()}`
        : undefined;

    const response = await axios.get(
      `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/logs`,
      {
        headers: {
          "locus-agent-env": environment,
        },
        params: {
          site,
          timestampRange: timestampRangeString,
          group: false,
          page,
          pageSize,
          task,
        },
      }
    );

    const flattenedData = flattenSiteActionLogs(response.data.data);
    dispatch(slice.actions.getSiteActionLogsSuccess(flattenedData));
    return response;
  };
}
function buildStatusBody(
  site,
  user,
  taskType,
  taskId,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  let postBody = {
    scheduledAt: new Date(scheduledAt).toISOString(), // Ensure the date is in UTC format
    status,
    taskType,
    task: taskId,
    user: {
      username: user.username,
      id: user.id,
    },
    arguments: args,
    site: site.id,
  };
  return postBody;
}

async function getTaskId(taskName) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TOOLING_API}/tooling/agenttask/find`,
      {
        params: {
          name: taskName
        }
      }
    );
    return response.data?.id || null;
  } catch(err) {
    console.error(err);
    throw err;
  }
} 

export function postStatusAgentCheck(
  site,
  user,
  environment,
  taskType,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  return async () => {
    try {
      const taskId = await getTaskId(taskType);
      
      const postBody = buildStatusBody(
        site,
        user,
        taskType,
        taskId,
        args,
        scheduledAt,
        status
      );

      const responseAgenttask = await axios.post(
        `${process.env.REACT_APP_TOOLING_API}/tooling/status/agenttask`,
        postBody, // Post body goes here
        {
          headers: {
            "locus-agent-env": environment,
          },
        },
      );
      dispatch(slice.actions.updateStatusSuccess("New"));
      return responseAgenttask;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export function getSiteActionOverview( site, environment, group=false ) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/overview`,
        {
          headers: {
            "locus-agent-env": environment,
          },
          params: {
            site: site,
            group: group,
          },
        },
      );
      const flattenedData = flattenSiteActionOverview(response.data.data);
      dispatch(slice.actions.getSiteActionOverviewSuccess(flattenedData));
      return flattenedData;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error);
    }
  };
}

export function getMerakiDevices({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedSiteId,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
        ...searchFilter,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevice/${selectedSiteId}`,
        {params},
        {},
      );
      dispatch(slice.actions.getMerakiDevicesSuccess({
        count: response.data?.count, 
        rows: response.data?.data,
      }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMerakiDevice(context) {
  return async () => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevicelockstate`,
        context,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getIntegrationMonitoringLogs({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedClient,
  selectedSite,
  viewState,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }
      if (viewState === "client" && selectedClient && selectedClient?.id) {
        params.client = selectedClient.id;
      }
      if (viewState === "site" && selectedSite && selectedSite?.id) {
        params.site = selectedSite.id;
      }

      params = { ...params, ...searchFilter };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
          params,
        },
        {},
      );
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(response.data),
      );
    } catch (error) {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess({
          count: 0,
          rows: [],
        }),
      );
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearIntegrationMonitoringLogs() {
  return async () => {
    try {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(
          initialState.integrationMonitoringLogs,
        ),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIntegrationMonitoringLogById({ selectedSite, logId }) {
  return async () => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog/${logId}`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
        },
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getUnassignedJobs({ site }) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TOOLING_API}/tooling/warehouse/job/unassigned`,
        {
          headers: {
            "locus-warehouse": site.condensedName,
          },
        }
      );
      dispatch(slice.actions.getUnassignedJobsSuccess({
        count: response.data.length,
        rows: response.data,
      }))
    } catch(error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getUnassignedJobsSuccess({
        count: 0,
        rows: [],
      }))
    }
  }
}

export function updateSelectedBotCalculator(payload) {
  return async () => {
    try {
      console.log('Dispatching bot calculator update with payload:', payload);
      dispatch(slice.actions.updateBotCalculator(payload));
    } catch (error) {
      console.error('Error updating bot calculator:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSelectedDesignVolume(key, picking, putaway) {
  return async () => {
    try {
      dispatch(slice.actions.updateSelectedDesignVolume({ key, picking, putaway }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
