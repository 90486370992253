// third-party
import { FormattedMessage } from "react-intl";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import CalculateIcon from "@mui/icons-material/Calculate";
import {
  LocusCMSMessagesIcon,
  LocusFleetAdminIcon,
  LocusFileUploadIcon,
  LocusDeviceConfigurationIcon,
} from "assets/icons/LocusIcons.js";
import {
  LOCUS_TENANT,
  LOCUS_TOOLS_USER,
  SUPER_ADMIN,
  SYSTEM_INTEGRATOR,
} from "../utils/constants/user";

// ==============================|| APPLICATION MENU ITEMS ||============================== //
/** @type {LocusMenuGroup} */
const tools = {
  id: "tools",
  title: <FormattedMessage id="tools" />,
  type: "group",
  view: ["site"],
  children: [
    {
      id: "site-actions",
      title: <FormattedMessage id="site-actions" />,
      type: "item",
      icon: CloudSyncIcon,
      url: "tools/site-actions?site=:siteId",
      breadcrumbs: true,
      view: ["site"],
      allowedGroups: [SUPER_ADMIN, LOCUS_TOOLS_USER],
    },
    {
      id: "device-configuration",
      title: <FormattedMessage id="ipad-access" />,
      type: "item",
      icon: LocusDeviceConfigurationIcon,
      url: "tools/device-configuration?site=:siteId",
      breadcrumbs: true,
      view: ["site"],
      allowedGroups: [LOCUS_TOOLS_USER],
    },
    {
      id: "integration-monitoring",
      title: <FormattedMessage id="integration-monitoring" />,
      type: "item",
      icon: LocusCMSMessagesIcon,
      url: "tools/integration-monitoring?site=:siteId",
      breadcrumbs: true,
      view: ["site"],
      allowedGroups: [LOCUS_TOOLS_USER],
    },
    {
      id: "system-integration",
      title: <FormattedMessage id="system-integration" />,
      type: "collapse",
      view: ["client"],
      icon: IntegrationInstructionsIcon,
      allowedGroups: [SUPER_ADMIN, SYSTEM_INTEGRATOR],
      allowedClients: ['sysis'], // restricted to clients with domain sysis
      children: [
        {
          id: "bot-calculator",
          title: <FormattedMessage id="bot-calculator" />,
          type: "item",
          icon: CalculateIcon,
          url: "tools/system-integration/bot-calculator?site=:siteId",
          breadcrumbs: true,
          allowedGroups: [SUPER_ADMIN, SYSTEM_INTEGRATOR],
          allowedClients: ['sysis'], // restricted to clients with domain sysis
          view: ["client"],
        },
      ],
    },
    {
      id: "fleet-admin",
      title: <FormattedMessage id="fleet-admin" />,
      type: "item",
      icon: LocusFleetAdminIcon,
      url: "https://prod.fleet.locusbots.io/overview",
      breadcrumbs: false,
      target: true,
      external: true,
      view: ["locus"],
      allowedGroups: [LOCUS_TENANT],
    },
  ],
};

export default tools;
